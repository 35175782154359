function _extends() { _extends = Object.assign ? Object.assign.bind() : function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; return _extends.apply(this, arguments); }
import { isSearchDataAvailable, isSearchDataStartsWith } from '../../../../utils';
export var DISPLAY_SEARCH_FIELD_ELEMENTS_NUMBER = 6;
export var searchFilters = [{
  label: 'Symbol Search',
  value: 'ticker_search'
}, {
  label: 'Search',
  value: 'portfolio_search'
}, {
  label: 'Search Portfolios',
  value: 'portfolio_search'
}, {
  label: 'Search Watchlists',
  value: 'portfolio_search'
}, {
  label: 'Ticker Search',
  value: 'ticker_country'
}];
export var nonStandardFilters = [{
  label: 'My Filters',
  value: 'custom_filter'
}, {
  label: 'My Watchlists',
  value: 'watchlist'
}].concat(searchFilters);
export var TEMP_FILTER_TO_REMOVE = '&General%5Eult_underlyingassettype=all';
export var getFiltersCount = function getFiltersCount(filtersState) {
  if (typeof filtersState === 'object' && filtersState !== null) {
    var keys = Object.keys(filtersState);
    var filtersCount = keys.reduce(function (acc, key) {
      var nonStandardFilter = _extends({}, nonStandardFilters.find(function (_ref) {
        var value = _ref.value;
        return value === key;
      }));
      if (key === nonStandardFilter.value) {
        var filterKey = nonStandardFilter.value;
        var filteredKeys = keys.filter(function (key) {
          return key.includes(filterKey);
        });
        acc += filteredKeys.length;
      }
      var _key$split = key.split('^'),
        category = _key$split[0];
      return key.includes('^') && category ? acc + 1 : acc;
    }, 0);
    return filtersCount;
  }
  return 0;
};
export var getFilterElementsAfterUseSearch = function getFilterElementsAfterUseSearch(_ref2) {
  var data = _ref2.data,
    searchValue = _ref2.searchValue;
  return function () {
    if (!searchValue) return [].concat(data);
    var startsWithSearch = data == null ? void 0 : data.filter(function (item) {
      return isSearchDataStartsWith({
        name: item.label,
        filter: searchValue
      });
    });
    var includesSearch = data == null ? void 0 : data.filter(function (item) {
      return isSearchDataAvailable({
        name: item.label,
        filter: searchValue
      });
    });
    var filteredArray = removeDuplicates([].concat(startsWithSearch, includesSearch));
    return filteredArray;
  };
};
var filterScreenerInputs = function filterScreenerInputs(_ref3) {
  var data = _ref3.data,
    searchValue = _ref3.searchValue,
    searchId = _ref3.searchId,
    mainSearch = _ref3.mainSearch;
  if (mainSearch) {
    return data.reduce(function (acc, next) {
      if (isSearchDataAvailable({
        name: next.name,
        filter: searchValue
      })) {
        return [].concat(acc, [_extends({}, next, {
          data: next.data
        })]);
      }
      return acc;
    }, []);
  }
  var filteredArray = data.reduce(function (acc, next) {
    var _newData3;
    var newData = null;
    if (next.type === 'slider' || next.type === 'date_range') {
      var newData1 = isSearchDataStartsWith({
        name: next.name,
        filter: searchValue
      }) ? [_extends({}, next)] : [];
      var newData2 = isSearchDataAvailable({
        name: next.name,
        filter: searchValue
      }) ? [_extends({}, next)] : [];
      newData = removeDuplicates([].concat(newData1, newData2));
    } else {
      var _next$data, _next$data2;
      var _newData = (_next$data = next.data) == null ? void 0 : _next$data.filter(function (item) {
        return isSearchDataStartsWith({
          name: item[searchId],
          filter: searchValue
        });
      });
      var _newData2 = (_next$data2 = next.data) == null ? void 0 : _next$data2.filter(function (item) {
        return isSearchDataAvailable({
          name: item[searchId],
          filter: searchValue
        });
      });
      newData = removeDuplicates([].concat(_newData, _newData2));
    }
    if ((_newData3 = newData) != null && _newData3.length) return [].concat(acc, [_extends({}, next, {
      data: newData
    })]);
    return [].concat(acc);
  }, []);
  return filteredArray;
};
export var getFiltersAfterUseSearch = function getFiltersAfterUseSearch(props) {
  return function () {
    var data = props.data,
      searchValue = props.searchValue;
    if (!data) return [];
    if (!searchValue) return Array.isArray(data) ? [].concat(data) : _extends({}, data);
    if (data.type === 'custom_filters') {
      if (isSearchDataAvailable({
        name: data.name,
        filter: searchValue
      })) {
        return _extends({}, data);
      }
      return {};
    }
    return filterScreenerInputs(props);
  };
};
export var isFilterFromGroupSelected = function isFilterFromGroupSelected(filtersState, name) {
  if (typeof filtersState === 'object' && filtersState !== null) {
    var keys = Object.keys(filtersState);
    var filteredKeys = keys.filter(function (key) {
      return key.includes(name + "^");
    });
    var nonStandardFilter = _extends({}, nonStandardFilters.find(function (_ref4) {
      var label = _ref4.label;
      return label === name;
    }));
    if (name === nonStandardFilter.label) {
      var filterKey = nonStandardFilter.value;
      var _filteredKeys = keys.filter(function (key) {
        return key.includes(filterKey);
      });
      return _filteredKeys.length;
    }
    return filteredKeys.length;
  }
  return false;
};
var removeDuplicates = function removeDuplicates(arr) {
  if (!arr) return [];
  return arr.reduce(function (acc, next) {
    var found = acc.find(function (item) {
      return (item == null ? void 0 : item.id) === (next == null ? void 0 : next.id);
    });
    if (!found) return acc.concat([next]);
    return acc;
  }, []);
};
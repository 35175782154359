import { Auth } from 'aws-amplify';
import { getConfigParam, setRequestOptions } from '../../utils';
export var requestReportPreferences = function requestReportPreferences(params) {
  var host = getConfigParam('api');
  var reqOptions = {
    authorization: null,
    data: params && params.data,
    method: params ? 'POST' : 'GET'
  };
  return Auth.currentAuthenticatedUser().then(function (_) {
    reqOptions.authorization = Auth.user.signInUserSession.idToken.jwtToken;
    return fetch(host + "/user/settings1", setRequestOptions(reqOptions)).then(function (res) {
      return res.json();
    }).then(function (res) {
      return res;
    });
  }).catch(function (_) {
    return {};
  });
};
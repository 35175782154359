import { getConfigParam } from '../../../utils';
import { v4 as uuidv4 } from 'uuid';
export var ACCEPT = '.png,.tiff,.jpeg,.jpg';
export var LIMIT = 1;
export var MIME = 'image/png,image/jpeg,image/tiff';
export var DEFAULT_LOGO = getConfigParam('server') + "/assets/images/vettafipro-logo.png";
export var DEFAULT_CLIENT_TABS = [{
  name: 'Client details',
  value: 'client',
  id: 'client-form'
}, {
  name: 'Questionnaire',
  value: 'questionnaire',
  id: 'questionnaire'
}];
export var DEFAULT_STATE = {
  authed: null,
  checkedSections: [],
  clearFileInput: false,
  currentFilter: '',
  details: {},
  downloadReport: false,
  excludesSections: [],
  isCollapsing: false,
  metaOptions: {},
  secondOverlay: false,
  sections: [],
  srcData: DEFAULT_LOGO,
  questionnaireData: {},
  questionnaireOpen: false
};
export var DEFAULT_DETAILS_STATE = {
  activeItem: null,
  address1: '',
  address2: '',
  clientEmailValid: null,
  client_email: '',
  client_first_name: '',
  client_last_name: '',
  clients: [],
  email: '',
  emailValid: null,
  firm: '',
  name: '',
  notes: '',
  object_id: '',
  phone: '',
  phoneValid: null,
  reqLoading: false,
  requiredField: false
};
export var META_DESCRIPTION = 'Some tables and graphics may be too large to display when input portfolios are too big. The PDF engine will automatically suppress these to enhance readability';
export var META_OPTIONS = [{
  filedTitle: 'Page Orientation',
  defaultOption: 'portrait',
  id: 'landscape_or_portrait',
  options: ['landscape', 'portrait'],
  radio: true
}, {
  checked: true,
  id: 'max_rows',
  label: 'Truncate Long Tables'
}, {
  checked: true,
  id: 'suppress_unsupported_items',
  label: 'Suppress Unsupported PDF Items'
}, {
  checked: true,
  id: 'email_pdf_status',
  label: 'Email Report Link to'
}, {
  checked: true,
  id: 'include_disclaimer',
  label: 'Include Disclaimer'
}];
export var TEXT_FIELDS = [{
  isRequired: true,
  label: 'Client email',
  name: 'client_email',
  type: 'email',
  validation: true
}, {
  isRequired: true,
  label: 'Client First Name',
  name: 'client_first_name',
  type: 'text',
  validation: true
}, {
  isRequired: true,
  label: 'Client Last Name',
  name: 'client_last_name',
  type: 'text',
  validation: true
}, {
  label: 'Firm',
  name: 'firm',
  type: 'text'
}, {
  label: 'Address Line 1',
  name: 'address1',
  type: 'text'
}, {
  label: 'Address Line 2',
  name: 'address2',
  type: 'text'
}, {
  label: 'Advisor',
  name: 'name',
  type: 'text'
}, {
  label: 'Advisor phone #',
  name: 'phone',
  type: 'text',
  validation: true
}, {
  authEmail: true,
  label: 'Advisor email',
  name: 'email',
  type: 'email',
  validation: true
}];
export var getToastMessage = function getToastMessage(_ref) {
  var save = _ref.save,
    success = _ref.success;
  var toast = {
    id: uuidv4(),
    message: "The client has been successfully " + (save ? 'saved' : 'updated') + ".",
    type: 'success'
  };
  if (!success) {
    Object.assign(toast, {
      message: 'An unexpected error has occurred.',
      type: 'error'
    });
  }
  return toast;
};
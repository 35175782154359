// eslint-disable-next-line import/prefer-default-export
export var getIntegrationFormConfig = function getIntegrationFormConfig(info, name) {
  switch (name) {
    case 'pershing':
      return [{
        icon: 'profile',
        key: 'first_name',
        label: 'First Name',
        required: true,
        type: 'text',
        val: info['custom:first']
      }, {
        icon: 'name',
        key: 'last_name',
        label: 'Last Name',
        required: true,
        type: 'text',
        val: info['custom:last']
      }, {
        icon: 'mail',
        key: 'email',
        label: 'Email',
        required: true,
        type: 'email',
        val: info.email
      }, {
        label: 'Notes',
        key: 'notes',
        type: 'textarea'
      }];
    case 'redtail':
      return [{
        icon: 'profile',
        key: 'user',
        label: 'Username',
        required: true,
        type: 'text'
      }, {
        icon: 'lock',
        key: 'password',
        label: 'Password',
        required: true,
        type: 'password'
      }];
    case 'schwab':
      return [{
        icon: 'profile',
        key: 'first_name',
        label: 'First Name',
        required: true,
        type: 'text',
        val: info['custom:first']
      }, {
        icon: 'name',
        key: 'last_name',
        label: 'Last Name',
        required: true,
        type: 'text',
        val: info['custom:last']
      }, {
        icon: 'mail',
        key: 'email',
        label: 'Email',
        required: true,
        type: 'email',
        val: info.email
      }, {
        icon: 'campus',
        key: 'schwab_advisor_number',
        label: 'Schwab Advisor Number',
        required: true,
        type: 'text'
      }, {
        label: 'Notes',
        key: 'notes',
        type: 'textarea'
      }];
  }
};
export var ACTIONS = [{
  label: 'Remove tickers',
  value: 'remove'
}];
export var ADDITIONAL_SEARCH_PREFIX = {
  basket: 'sectycd=UNT,MF,ETF,SP&',
  comboTmx: 'exchgcntry=CA',
  hours: 'sectycd=ETF',
  liquidity: 'sectycd=ETF,SP&',
  single: 'sectycd=ETF,UNT'
};
export var COUNTRY_BUTTONS_GRAPEVINE = [{
  label: 'Canada',
  value: 'CA'
}, {
  label: 'USA',
  value: 'US'
}];
export var DATE_PICKER_MONTH_PICKER = ['/performance'];
export var LOOKBACK_BUTTONS_GRAPEVINE = [{
  label: '1W',
  value: 7
}, {
  label: '1M',
  value: 1
}, {
  label: '3M',
  value: 3
}, {
  label: '6M',
  value: 6
}, {
  label: '1Y',
  value: 12
}];
export var LOOKBACK_BUTTONS_FLOWS = [{
  label: 'YTD',
  value: 'YTD'
}, {
  label: '1D',
  value: '1D'
}, {
  label: '1W',
  value: '1W'
}, {
  label: '1M',
  value: '1M'
}, {
  label: '2M',
  value: '2M'
}, {
  label: '3M',
  value: '3M'
}, {
  label: '6M',
  value: '6M'
}, {
  label: '1Y',
  value: '1Y'
}, {
  label: '3Y',
  value: '3Y'
}, {
  label: '5Y',
  value: '5Y'
}];
export var PERFORMANCE_START_DATE = new Date('2017-05-01T00:00-00:00');
export var REVERSE_TOGGLE_INFO = ["Intersection mode finds ETFs that contain all selected tickers.\n  Union mode finds ETFs that contain at least one of the selected\n  tickers."];
export var SHELF_TOASTS = {
  basketReverseCheckboxes: ['Check at least one of the options (ETF / Mutual Fund).'],
  tickGrapevineCheckboxes: ['Check at least one of the options.']
};
export var SOURCE_PARAMETERS = {
  basket: 'source=overlap-correlation',
  liquidity: 'source=basket-truliquidity',
  performance: 'source=performance-attribution',
  prospector: 'source=tax-loss-prospector'
};
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { scrollElement } from '../../utils';
var ScrollToTop = function ScrollToTop() {
  var _useLocation = useLocation(),
    pathname = _useLocation.pathname;
  var excludedScrollPath = ['/integrations', '/guide'];
  useEffect(function () {
    if (!excludedScrollPath.includes(pathname)) {
      var docAnchor = document.getElementById('app-container');
      docAnchor && docAnchor.scrollTop > 0 && scrollElement(docAnchor);
    }
  }, [pathname]);
  return null;
};
export default ScrollToTop;
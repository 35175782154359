import { isFloat, setNumberWordAndSuffix } from '../../../../utils';
import intl from '../../../../../shared/data/intl.json';
export var currencySupported = ['AUD', 'CAD', 'USD', 'EUR', 'JPY', 'GBP', 'GBX'];
export var formatNumber = function formatNumber(bigText, currency, percent, metaPrice) {
  if (currency === void 0) {
    currency = null;
  }
  if (percent === void 0) {
    percent = null;
  }
  if (metaPrice === void 0) {
    metaPrice = null;
  }
  var next = bigText,
    suffix = '';
  var args = {
    currency: 'USD',
    maximumFractionDigits: 2,
    minimumFractionDigits: 0,
    style: 'decimal',
    useGrouping: true
  };
  if (currency && intl.currency.some(function (cy) {
    return cy.code === currency;
  })) {
    args.currency = currency;
    args.style = 'currency';
  }
  if (percent) {
    args.maximumFractionDigits = 5;
    args.style = 'percent';
  }
  var _setNumberWordAndSuff = setNumberWordAndSuffix({
      next: next
    }),
    nextVal = _setNumberWordAndSuff[0],
    suffixVal = _setNumberWordAndSuff[1];
  next = nextVal || next;
  suffix = suffixVal || suffix;
  if (metaPrice && isFloat(next)) args.minimumFractionDigits = 2;
  return "" + new Intl.NumberFormat('en-US', args).format(next) + suffix;
};
export var CLEAR_DEFAULT_PORTFOLIO = 'CLEAR_DEFAULT_PORTFOLIO';
export var CLEAR_TOASTS = 'CLEAR_TOASTS';
export var PORTFOLIO_WIDGET_MODAL_CHANGES = 'PORTFOLIO_WIDGET_MODAL_CHANGES';
export var SET_ADD_TO_WATCHLIST = 'SET_ADD_TO_WATCHLIST';
export var SET_ADVANCED_MODAL = 'SET_ADVANCED_MODAL';
export var SET_AUTH_CLOSE_OVERLAY = 'SET_AUTH_CLOSE_OVERLAY';
export var SET_AUTH_EVENT = 'SET_AUTH_EVENT';
export var SET_AUTH_INFO = 'SET_AUTH_INFO';
export var SET_AXOS_FLAG = 'SET_AXOS_FLAG';
export var SET_CABINET_MODE = 'SET_CABINET_MODE';
export var SET_CABINET_NAV = 'SET_CABINET_NAV';
export var SET_CARDS = 'SET_CARDS';
export var SET_CARDS_BUCKET = 'SET_CARDS_BUCKET';
export var SET_COACH_MONITORING = 'SET_COACH_MONITORING';
export var SET_COACH_PORTFOLIOS = 'SET_COACH_PORTFOLIOS';
export var SET_COACH_SYNC_LIST = 'SET_COACH_SYNC_LIST';
export var SET_CREATE_USER_FROM_DASHBOARD = 'SET_CREATE_USER_FROM_DASHBOARD';
export var SET_CREATE_WATCHLIST = 'SET_CREATE_WATCHLIST';
export var SET_CURRENT_CUSTOM_VIEW = 'SET_CURRENT_CUSTOM_VIEW';
export var SET_CURRENT_SHELF = 'SET_CURRENT_SHELF';
export var SET_CURRENT_USER = 'SET_CURRENT_USER';
export var SET_CUSTOM_FILTERS = 'SET_CUSTOM_FILTERS';
export var SET_CUSTOM_VIEWS = 'SET_CUSTOM_VIEWS';
export var SET_DEFAULT_BENCHMARK = 'SET_DEFAULT_BENCHMARK';
export var SET_DEFAULT_CONTROLS = 'SET_DEFAULT_CONTROLS';
export var SET_DEFAULT_ROWS_COUNT = 'SET_DEFAULT_ROWS_COUNT';
export var SET_DELETE_WATCHLIST_ITEM = 'SET_DELETE_WATCHLIST_ITEM';
export var SET_DISPLAY_SECURITIES = 'SET_DISPLAY_SECURITIES';
export var SET_DYNAMIC_SECTIONS = 'SET_DYNAMIC_SECTIONS';
export var SET_EMAIL_ALERTS = 'SET_EMAIL_ALERTS';
export var SET_INTEGRATION_PROCESS_QUEUE = 'SET_INTEGRATION_PROCESS_QUEUE';
export var SET_INTEGRATIONS = 'SET_INTEGRATIONS';
export var SET_INTEGRATIONS_LOADER = 'SET_INTEGRATIONS_LOADER';
export var SET_JUMP_LINKS = 'SET_JUMP_LINKS';
export var SET_JUMP_TO_SECTION = 'SET_JUMP_TO_SECTION';
export var SET_LANDING_TRENDS = 'SET_LANDING_TRENDS';
export var SET_LANDING_TRENDS_LIST = 'SET_LANDING_TRENDS_LIST';
export var SET_LIGHT_MODE = 'SET_LIGHT_MODE';
export var SET_LOGIN_2FA = 'SET_LOGIN_2FA';
export var SET_MACHINE = 'SET_MACHINE';
export var SET_MACHINE_APPLY = 'SET_MACHINE_APPLY';
export var SET_MACHINE_CONTEXT = 'SET_MACHINE_CONTEXT';
export var SET_MACHINE_REQUEST = 'SET_MACHINE_REQUEST';
export var SET_MASQ_MODE = 'SET_MASQ_MODE';
export var SET_NAVIGATION = 'SET_NAVIGATION';
export var SET_NAVIGATION_LOADING = 'SET_NAVIGATION_LOADING';
export var SET_NETWORK_ERROR = 'SET_NETWORK_ERROR';
export var SET_OPEN_OVERLAY = 'SET_OPEN_OVERLAY';
export var SET_OPEN_WALKTHROUGH = 'SET_OPEN_WALKTHROUGH';
export var SET_ORDERED_REPORT_SECTIONS = 'SET_ORDERED_REPORT_SECTIONS';
export var SET_QUILT_TIME_PERIOD = 'SET_QUILT_TIME_PERIOD';
export var SET_PAGINATION = 'SET_PAGINATION';
export var SET_PORTFOLIOS = 'SET_PORTFOLIOS';
export var SET_PREV_MACHINE_PARAMS = 'SET_PREV_MACHINE_PARAMS';
export var SET_PRIMARY_PORTFOLIO = 'SET_PRIMARY_PORTFOLIO';
export var SET_REACT_TABLE_ACTION = 'SET_REACT_TABLE_ACTION';
export var SET_REPORT_LOADING = 'SET_REPORT_LOADING';
export var SET_REPORT_PREFERENCES = 'SET_REPORT_PREFERENCES';
export var SET_SCROLLBAR_OFFSET = 'SET_SCROLLBAR_OFFSET';
export var SET_SEARCHBAR_MODE = 'SET_SEARCHBAR_MODE';
export var SET_SECONDARY_PORTFOLIO = 'SET_SECONDARY_PORTFOLIO';
export var SET_SHELF_MODE = 'SET_SHELF_MODE';
export var SET_SIDEBAR_MODE = 'SET_SIDEBAR_MODE';
export var SET_STARRED_ITEM = 'SET_STARRED_ITEM';
export var SET_TAB_VIEW = 'SET_TAB_VIEW';
export var SET_TABLE_DOWNLOAD = 'SET_TABLE_DOWNLOAD';
export var SET_TABLE_DOWNLOADING = 'SET_TABLE_DOWNLOADING';
export var SET_TOAST = 'SET_TOAST';
export var SET_TOASTS_POSITION = 'SET_TOASTS_POSITION';
export var SET_TOGGLE_PLAID_CONFIG = 'SET_TOGGLE_PLAID_CONFIG';
export var SET_TRIAL_SUBSCRIPTION = 'SET_TRIAL_SUBSCRIPTION';
export var SET_TRANSITION_BUTTON_VISIBLE = 'SET_TRANSITION_BUTTON_VISIBLE';
export var SET_UNSAVED_PW_CHANGES = 'SET_UNSAVED_PW_CHANGES';
export var SET_UPDATE_USER_TIER = 'SET_UPDATE_USER_TIER';
export var SET_USER_DETAILS = 'SET_USER_DETAILS';
export var SET_USERS = 'SET_USERS';
export var SET_WATCHLISTS = 'SET_WATCHLISTS';
export var SET_WIDGET_MODE = 'SET_WIDGET_MODE';
export var SET_WINDOW_WIDTH = 'SET_WINDOW_WIDTH';
export var SET_WORLD_MODE = 'SET_WORLD_MODE';
import { useEffect, useState } from 'react';

// eslint-disable-next-line import/prefer-default-export
export var useQuickDate = function useQuickDate(_ref) {
  var endDate = _ref.endDate,
    startDate = _ref.startDate;
  var _useState = useState(null),
    quickDate = _useState[0],
    setQuickDate = _useState[1];
  useEffect(function () {
    if (endDate && startDate) updateQuickDate();
  }, [endDate, startDate]);
  var updateQuickDate = function updateQuickDate() {
    if (window.moment(endDate).isSame(window.moment(), 'day')) {
      var monthsAmount = [1, 3, 6, 12];
      var _quickDate = null;
      for (var i = 0; i < monthsAmount.length; i++) {
        if (window.moment().subtract(monthsAmount[i], 'months').isSame(window.moment(startDate), 'days')) {
          _quickDate = "" + (monthsAmount[i] === 12 ? '1' : monthsAmount[i]) + (monthsAmount[i] === 12 ? 'Y' : 'M');
          break;
        }
      }
      if (window.moment().subtract(1, 'week').isSame(startDate, 'days')) {
        _quickDate = "1W";
      }
      setQuickDate(_quickDate);
    } else {
      setQuickDate(null);
    }
  };
  return {
    quickDate: quickDate
  };
};
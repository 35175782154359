import { useSelector } from 'react-redux';
var selectors = function selectors() {
  var cabinetMode = useSelector(function (_ref) {
    var cabinetMode = _ref.cabinetMode;
    return cabinetMode;
  });
  var cabinetNav = useSelector(function (_ref2) {
    var cabinetNav = _ref2.cabinetNav;
    return cabinetNav;
  });
  var cardsBucket = useSelector(function (_ref3) {
    var cardsBucket = _ref3.cardsBucket;
    return cardsBucket;
  });
  var defaultRowsCount = useSelector(function (_ref4) {
    var defaultRowsCount = _ref4.defaultRowsCount;
    return defaultRowsCount || 15;
  });
  var machine = useSelector(function (_ref5) {
    var machine = _ref5.machine;
    return machine;
  });
  var machineContext = useSelector(function (_ref6) {
    var machineContext = _ref6.machineContext;
    return machineContext;
  });
  return {
    cabinetMode: cabinetMode,
    cabinetNav: cabinetNav,
    cardsBucket: cardsBucket,
    defaultRowsCount: defaultRowsCount,
    machineContext: machineContext,
    machineData: machine == null ? void 0 : machine.data,
    machineError: machine == null ? void 0 : machine.error,
    machineId: machine == null ? void 0 : machine.id
  };
};
export default selectors;
export var viewsAPIConfig = {
  'eq-screener': {
    customViewsConfig: {
      key: 'custom-views',
      path: 'custom-view-eq-screener'
    },
    selectedViewsConfig: {
      key: 'selectedViews',
      path: 'eq-selected-views'
    }
  },
  'investor-flow': {
    customViewsConfig: {
      key: 'custom-views',
      path: 'custom-view-investor-flow-data'
    },
    selectedViewsConfig: {
      key: 'selectedViews',
      path: 'investor-flow-selected-views'
    }
  },
  'my-portfolios': {
    customViewsConfig: {
      key: 'custom-views',
      path: 'custom-view-my-portfolios'
    },
    selectedViewsConfig: {
      key: 'selectedViews',
      path: 'my-portfolios-selected-views'
    }
  },
  screener: {
    customViewsConfig: {
      key: 'custom-views',
      path: 'custom-view'
    },
    selectedViewsConfig: {
      key: 'selectedViews',
      path: 'screener-selected-views'
    }
  },
  'screener-records': {
    customViewsConfig: {
      key: 'custom-views',
      path: 'custom-view-screener-records'
    },
    selectedViewsConfig: {
      key: 'selectedViews',
      path: 'screener-records-selected-views'
    }
  }
};
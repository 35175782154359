function _extends() { _extends = Object.assign ? Object.assign.bind() : function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; return _extends.apply(this, arguments); }
import { setCurrentShelf } from '../../../../../redux/actionCreators';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import shelvesData from '../../../../../../shared/data/shelves.json';
var shelfBase = shelvesData.shelfBase,
  shelfData = shelvesData.shelfData;
var getFormattedPathname = function getFormattedPathname(pathname) {
  if (pathname.includes('/hours')) return '/hours';
  return pathname;
};

// eslint-disable-next-line import/prefer-default-export
export var useShelf = function useShelf(_ref) {
  var isWidget = _ref.isWidget;
  var _useState = useState(true),
    isShelfVisible = _useState[0],
    setIsShelfVisible = _useState[1];
  var _useState2 = useState(shelfBase.tools),
    toolsVisibility = _useState2[0],
    setToolsVisibility = _useState2[1];
  var sectionsLength = useSelector(function (_ref2) {
    var _machine$data;
    var machine = _ref2.machine;
    return machine == null || (_machine$data = machine.data) == null || (_machine$data = _machine$data.sections) == null ? void 0 : _machine$data.length;
  });
  var _useLocation = useLocation(),
    pathname = _useLocation.pathname,
    search = _useLocation.search;
  var dispatch = useDispatch();
  useEffect(function () {
    var formattedPathname = getFormattedPathname(pathname);
    var shelfSrc = shelfData[formattedPathname];
    if (!shelfSrc || !window.tmxMode && shelfSrc != null && shelfSrc.tmxOnly) {
      setIsShelfVisible(false);
    } else {
      setIsShelfVisible(true);
    }
    var shelfObj = _extends({}, shelfBase, shelfSrc);
    checkToolsVisibility(shelfObj);
    var formattedShelfObj = formatShelfObj(shelfObj);
    dispatch(setCurrentShelf(formattedShelfObj));
  }, [pathname]);
  useEffect(function () {
    if (sectionsLength && pathname === '/view' && !search.includes('saved-results')) {
      setToolsVisibility(function (prev) {
        return _extends({}, prev, {
          jump: sectionsLength > 1
        });
      });
    }
  }, [sectionsLength]);
  var checkToolsVisibility = function checkToolsVisibility(shelfData) {
    var _shelfData$tools = shelfData.tools,
      help = _shelfData$tools.help,
      jump = _shelfData$tools.jump,
      pdf = _shelfData$tools.pdf,
      saveResults = _shelfData$tools.saveResults,
      xlsx = _shelfData$tools.xlsx;
    var isViewCreatedFromSavedResults = search.includes('saved-results');
    var isJumpVisible = jump && !isViewCreatedFromSavedResults && !isWidget;
    var isSavedResultsVisible = saveResults && !isViewCreatedFromSavedResults;
    setToolsVisibility({
      help: help,
      jump: isJumpVisible,
      saveResults: isSavedResultsVisible,
      pdf: pdf,
      xlsx: xlsx
    });
  };
  var formatShelfObj = function formatShelfObj(shelfData) {
    var shelfLabel = shelfData.label;
    if (shelfData.shelf === 'view') {
      var _parsedSearch$get;
      var parsedSearch = new URLSearchParams(search);
      shelfLabel = parsedSearch.get('label');
      if ((_parsedSearch$get = parsedSearch.get('src')) != null && _parsedSearch$get.includes('admin/tenancy')) {
        shelfLabel = 'Tenancies';
      } else if (shelfLabel === 'None') {
        shelfLabel = '';
      }
    }
    return {
      icon: shelfData.icon,
      iconName: shelfData.iconName,
      info: shelfData.info,
      label: shelfLabel,
      name: shelfData.name,
      notes: shelfData.notes,
      shelf: shelfData.shelf,
      size: shelfData.size,
      type: shelfData.type
    };
  };
  return {
    isHelpVisible: toolsVisibility.help,
    isJumpToolVisible: toolsVisibility.jump,
    isPdfVisible: toolsVisibility.pdf,
    isSavedResultsVisible: toolsVisibility.saveResults,
    isShelfVisible: isShelfVisible,
    isXlsxVisible: toolsVisibility.xlsx
  };
};
function _objectDestructuringEmpty(obj) { if (obj == null) throw new TypeError("Cannot destructure " + obj); }
function _extends() { _extends = Object.assign ? Object.assign.bind() : function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; return _extends.apply(this, arguments); }
/* eslint-disable import/prefer-default-export */
import { Auth } from 'aws-amplify';
import { useLocation, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useState, useEffect } from 'react';
export var useAuth = function useAuth(_temp, _temp2) {
  var _ref = _temp === void 0 ? {} : _temp,
    data = _extends({}, (_objectDestructuringEmpty(_ref), _ref));
  var _ref2 = _temp2 === void 0 ? data : _temp2,
    _ref2$addQuery = _ref2.addQuery,
    addQuery = _ref2$addQuery === void 0 ? false : _ref2$addQuery,
    callbackParams = _ref2.callbackParams,
    shouldRedirect = _ref2.shouldRedirect;
  var _useState = useState(false),
    isAuthed = _useState[0],
    setAuthed = _useState[1];
  var currentUser = useSelector(function (_ref3) {
    var currentUser = _ref3.currentUser;
    return currentUser;
  });
  var location = useLocation();
  var navigate = useNavigate();
  useEffect(function () {
    return function () {
      return setAuthed(false);
    };
  }, []);
  useEffect(function () {
    Auth.currentAuthenticatedUser().then(function () {
      return !isAuthed && setAuthed(true);
    }).catch(function () {
      isAuthed && setAuthed(false);
      if (shouldRedirect) {
        navigate('/signup', {
          replace: true,
          state: _extends({}, callbackParams && {
            callbackParams: callbackParams
          }, {
            type: 'login',
            prevPath: "" + location.pathname + (addQuery ? location.search : '')
          })
        });
      }
    });
  }, [currentUser]);
  return isAuthed;
};
function _extends() { _extends = Object.assign ? Object.assign.bind() : function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; return _extends.apply(this, arguments); }
var getRTColumns = function getRTColumns(_ref) {
  var data = _ref.data,
    type = _ref.type;
  if (!data || !data.data) return [];
  return data.data.columns.map(function (column) {
    return _extends({}, column, {
      accessor: column.field || 'Unknown',
      Header: column.title || ''
    }, type === 'table-pivot' && {
      dataType: 'cell'
    });
  }).filter(function (col) {
    return data.data.columns.filter(function (nextCol) {
      return (col == null ? void 0 : col.field) === (nextCol == null ? void 0 : nextCol.field);
    }).length === 1;
  });
};
export var getSectionRTData = function getSectionRTData(_ref2) {
  var data = _ref2.data,
    tabulatorForce = _ref2.tabulatorForce;
  if (tabulatorForce) return data;
  if (!data || !data.data) return data;
  if (data.type === 'table' || data.type === 'table-pivot') {
    var _data$data;
    if (!(data != null && (_data$data = data.data) != null && _data$data.columns)) return data;
    data.data.columns = getRTColumns({
      data: data,
      type: data.type
    });
    data.original_type = data.type;
    data.type = getSectionType({
      tabulatorForce: tabulatorForce,
      type: data.type
    });
    return data;
  }
  if (data.type === 'table-group' || data.type === 'mixed') {
    var _data$data2;
    data.data && ((_data$data2 = data.data) == null ? void 0 : _data$data2.map(function (section) {
      if (section.type.includes('table') && !section.type.includes('react')) {
        var _section$data;
        if (!(section != null && (_section$data = section.data) != null && _section$data.columns)) return section;
        section.data.columns = getRTColumns({
          data: section,
          type: section.type
        });
        section.original_type = section.type;
        section.type = getSectionType({
          tabulatorForce: tabulatorForce,
          type: section.type
        });
        return section;
      }
      return section;
    }));
    data.original_type = data.type;
    data.type = getSectionType({
      tabulatorForce: tabulatorForce,
      type: data.type
    });
    return data;
  }
  return data;
};
var getSectionType = function getSectionType(_ref3) {
  var tabulatorForce = _ref3.tabulatorForce,
    type = _ref3.type;
  if (tabulatorForce) return type;
  if (type === 'table') return 'table-react';
  if (type === 'table-group') return 'table-react-group';
  if (type === 'table-pivot') return 'table-react-pivot';
  return type;
};
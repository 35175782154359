export var CUSTODIAN = [{
  label: 'AXOS Clearing LLC',
  value: 'axos_clearing_llc'
}, {
  label: 'Charles Schwab & Co., Inc',
  value: 'charles_schwab'
}, {
  label: 'Fidelity/NFS/National Financia',
  value: 'fidelity-nfs-national_financia'
}, {
  label: 'Interactive Brokers LLC',
  value: 'interactive_brokers_llc'
}, {
  label: 'LPL Financial LLC',
  value: 'lpl_financial_llc'
}, {
  label: 'Pershing LLC',
  value: 'pershing_llc'
}, {
  label: 'Raymond James Custody',
  value: 'raymond_james_custody'
}, {
  label: 'TD Ameritrade',
  value: 'td_ameritrade'
}, {
  label: 'Other',
  value: 'other'
}];
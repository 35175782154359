/* eslint-disable import/prefer-default-export */
import { objLength } from '../../../../utils';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { usePreviousValue } from '../../../../hooks/usePreviousValue';
export var useReloadAfterLogOut = function useReloadAfterLogOut() {
  var currentUser = useSelector(function (_ref) {
    var currentUser = _ref.currentUser;
    return currentUser;
  });
  var prevCurrentUser = usePreviousValue(currentUser);
  useEffect(function () {
    if (prevCurrentUser && objLength(prevCurrentUser) > 0 && currentUser && objLength(currentUser) === 0) {
      window.location.reload();
    }
  }, [currentUser]);
};
export var FIT_DATA_FILL_OPTIONS = {
  layout: 'fitDataFill',
  resize: true
};
export var MACHINE_NAMES = ['flows', 'general', 'liqudity', 'tick_analytics'];
export var TABS_WITH_ALL_SECTIONS_IN_GRID = ['flows'];
var checkPivotData = function checkPivotData(pivot1, pivot2) {
  var isPivotDataValid = function isPivotDataValid(pivot) {
    var _pivot$data;
    return pivot == null || (_pivot$data = pivot.data) == null || (_pivot$data = _pivot$data.rows) == null ? void 0 : _pivot$data.some(function (val) {
      return Object.keys(val).filter(function (row) {
        return row !== '';
      }).length > 1;
    });
  };
  var isPivot1Valid = isPivotDataValid(pivot1);
  var isPivot2Valid = isPivotDataValid(pivot2);
  return isPivot1Valid && isPivot2Valid;
};
export var getPath = function getPath(_ref) {
  var bbcid = _ref.bbcid,
    bbeid = _ref.bbeid,
    scexhid = _ref.scexhid,
    secid = _ref.secid;
  return "https://app.vettafi.pro/overview?view=general&secid=" + secid + "&scexhid=" + scexhid + "&bbcid=" + bbcid + "&bbeid=" + bbeid;
};
export var showSectionsConditions = function showSectionsConditions(_ref2) {
  var displayDefaultViewIfNoSearchParams = _ref2.displayDefaultViewIfNoSearchParams,
    isMachineRequest = _ref2.isMachineRequest,
    loadingChangeTabData = _ref2.loadingChangeTabData,
    metaLoading = _ref2.metaLoading,
    metaSection = _ref2.metaSection,
    queryParamsObj = _ref2.queryParamsObj,
    sections = _ref2.sections,
    viewMetaSection = _ref2.viewMetaSection;
  var showMetaSectionElement = metaSection && viewMetaSection && !metaLoading;
  var showErrorSectionElement = (sections == null ? void 0 : sections.length) === 2 && !isMachineRequest;
  var showTabSectionsElement = (queryParamsObj == null ? void 0 : queryParamsObj.view) === (viewMetaSection == null ? void 0 : viewMetaSection.selectedView) && !loadingChangeTabData && MACHINE_NAMES.includes(queryParamsObj.view) && (sections == null ? void 0 : sections.length) > 0 && !isMachineRequest;
  var showOtherSectionsElement = queryParamsObj && !MACHINE_NAMES.includes(queryParamsObj.view) && (sections == null ? void 0 : sections.length) > 0 && !isMachineRequest;
  var showDefaultViewElement = displayDefaultViewIfNoSearchParams() || isMachineRequest;
  var showNoSectionsElement = !showDefaultViewElement && (sections == null ? void 0 : sections.length) === 0 && !isMachineRequest;
  return {
    showDefaultViewElement: showDefaultViewElement,
    showErrorSectionElement: showErrorSectionElement,
    showMetaSectionElement: showMetaSectionElement,
    showNoSectionsElement: showNoSectionsElement,
    showOtherSectionsElement: showOtherSectionsElement,
    showTabSectionsElement: showTabSectionsElement
  };
};
export var showOverviewGroupContidions = function showOverviewGroupContidions(_ref3) {
  var queryParamsObj = _ref3.queryParamsObj,
    sections = _ref3.sections;
  var pivotData = checkPivotData(sections[2], sections[3]);
  var machineNamesDontInclude = (queryParamsObj == null ? void 0 : queryParamsObj.view) && !MACHINE_NAMES.includes(queryParamsObj.view);
  var showIfLessThanFour = (sections == null ? void 0 : sections.length) < 4 && (sections == null ? void 0 : sections.length) >= 2 || machineNamesDontInclude;
  var showIfMoreThanFour = (sections == null ? void 0 : sections.length) >= 4 && !pivotData;
  var showColumnSection = (sections == null ? void 0 : sections.length) > 3 && pivotData;
  var showTransparentColumn = !TABS_WITH_ALL_SECTIONS_IN_GRID.includes(queryParamsObj == null ? void 0 : queryParamsObj.view);
  return {
    showColumnSection: showColumnSection,
    showIfLessThanFour: showIfLessThanFour,
    showIfMoreThanFour: showIfMoreThanFour,
    showTransparentColumn: showTransparentColumn
  };
};
import ReactGA from 'react-ga4';
export var Event = function Event(category, action, label) {
  ReactGA.event({
    category: category,
    action: action,
    label: label
  });
};
export var initGA = function initGA(trackingID) {
  ReactGA.initialize(trackingID);
};
export var PageView = function PageView() {
  ReactGA.send({
    hitType: 'pageview',
    page: window.location.pathname + window.location.search
  });
};
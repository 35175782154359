var _excluded = ["shouldReplace"];
function _extends() { _extends = Object.assign ? Object.assign.bind() : function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; return _extends.apply(this, arguments); }
function _objectWithoutPropertiesLoose(source, excluded) { if (source == null) return {}; var target = {}; var sourceKeys = Object.keys(source); var key, i; for (i = 0; i < sourceKeys.length; i++) { key = sourceKeys[i]; if (excluded.indexOf(key) >= 0) continue; target[key] = source[key]; } return target; }
/* eslint-disable import/prefer-default-export */
import { action_type as reducer_action_type } from '../AdvancedFilters/useStateReducer';
import { GENERAL_IS_PRIMARY_FILTER_KEY } from '../../MachineCabinet/components/NestedFilter/utils';
import { setOpenOverlay, setReactTableAction } from '../../../../redux/actionCreators';
import { TABLE_ACTIONS } from '../../../Tables/TableReact/utils';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { usePreviousValue } from '../../../../hooks/usePreviousValue';
import _omit from 'lodash/omit';
import isEqual from 'react-fast-compare';
export var useTableReactActions = function useTableReactActions(_ref) {
  var dispatchFiltersState = _ref.dispatchFiltersState,
    filtersState = _ref.filtersState,
    uploadReplaceAfterUploadingFilters = _ref.uploadReplaceAfterUploadingFilters;
  var portfolioListAction = useSelector(function (state) {
    return state.portfolioListAction;
  });
  var prevPortfolioListAction = usePreviousValue(portfolioListAction);
  var dispatch = useDispatch();
  useEffect(function () {
    if (portfolioListAction && !isEqual(portfolioListAction, prevPortfolioListAction)) {
      var action_type = portfolioListAction.action_type,
        actionData = portfolioListAction.data;
      var shouldReplace = actionData.shouldReplace,
        data = _objectWithoutPropertiesLoose(actionData, _excluded);
      switch (action_type) {
        case TABLE_ACTIONS.ADD_TO_WATCHLIST:
          dispatch(setOpenOverlay(data));
          break;
        case TABLE_ACTIONS.UPDATE_FILTERS:
          {
            var updateFilterData = data[GENERAL_IS_PRIMARY_FILTER_KEY] === 'all' ? _extends({}, _omit(data, [GENERAL_IS_PRIMARY_FILTER_KEY])) : _extends({}, data);
            dispatchFiltersState({
              data: _extends({}, updateFilterData, {
                view: data.view || filtersState.view || 'overview'
              }),
              type: reducer_action_type.FILTER_PUT_CHANGE
            });
            shouldReplace === false && uploadReplaceAfterUploadingFilters(false);
          }
          break;
        default:
          break;
      }
      dispatch(setReactTableAction(null));
    }
  }, [portfolioListAction]);
};
function _extends() { _extends = Object.assign ? Object.assign.bind() : function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; return _extends.apply(this, arguments); }
export var filterColumnsGroups = function filterColumnsGroups(_ref) {
  var groups = _ref.groups,
    searchValue = _ref.searchValue;
  return function () {
    if (!groups) return [];
    if (!searchValue) return [].concat(groups);
    var newFilteredGroups = groups.reduce(function (acc, group) {
      var filteredColumns = group.columns.filter(function (column) {
        return column.name.toLowerCase().includes(searchValue.toLowerCase().trim());
      });
      if (filteredColumns.length === 0) return acc;
      var updatedGroup = _extends({}, group, {
        columns: filteredColumns
      });
      return [].concat(acc, [updatedGroup]);
    }, []);
    return newFilteredGroups;
  };
};
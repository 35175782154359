import Auth from '../components/Auth/Auth';
import Clients from '../components/Machines/Clients/Clients';
import CoachModerate from '../components/Coach/CoachModerate';
import ContactSales from '../components/Docs/ContactSales';
import DocumentsCenter from '../components/Machines/DocumentsCenter/DocumentsCenter';
import IntegrationDetails from '../components/Integrations/IntegrationDetails';
import Integrations from '../components/Integrations/Integrations';
import Landing from '../components/Landing/Landing';
import Trends from '../components/Trends/Trends';
import Legal from '../components/Docs/Legal';
import Message from '../components/Tools/Message';
import Privacy from '../components/Docs/Privacy';
import SavedResults from '../components/SavedResults/SavedResults';
import Settings from '../components/Settings/Settings';
import Subscription from '../components/Subscription/Subscription';
import TimeSeries from '../components/TimeSeries/TimeSeries';
import Terms from '../components/Docs/Terms';
import UserDetail from '../components/AdminDashboard/UserDetail/UserDetail';
import UsersManagment from '../components/AdminDashboard/UsersManagment/UsersManagment';
var components = {
  'admin-users': UsersManagment,
  auth: Auth,
  clients: Clients,
  'coach-details': CoachModerate,
  documents: DocumentsCenter,
  integrations: Integrations,
  'integrations-list': IntegrationDetails,
  landing: Landing,
  legal: Legal,
  message: Message,
  privacy: Privacy,
  results: SavedResults,
  sales: ContactSales,
  settings: Settings,
  subscription: Subscription,
  terms: Terms,
  timeseries: TimeSeries,
  trending: Trends,
  'user-detail': UserDetail
};
export default components;
function _extends() { _extends = Object.assign ? Object.assign.bind() : function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; return _extends.apply(this, arguments); }
import { useContext, useEffect, useState } from 'react';
import { TieringContext } from '../providers/TieringProvider';

// eslint-disable-next-line import/prefer-default-export
export function useTieringSection(section) {
  var _useState = useState(false),
    isAvailable = _useState[0],
    setIsAvailable = _useState[1];
  var useTieringContext = useContext(TieringContext);
  var _useTieringContext = _extends({}, useTieringContext),
    availableSectionsNames = _useTieringContext.availableSectionsNames,
    downloadTool = _useTieringContext.downloadTool;
  useEffect(function () {
    if (downloadTool) {
      if (section && section.name && availableSectionsNames.some(function (name) {
        return name === section.name;
      })) {
        setIsAvailable(true);
      }
    } else {
      setIsAvailable(true);
    }
  }, [availableSectionsNames, section]);
  return isAvailable;
}
/* eslint-disable import/prefer-default-export */
import moment from 'moment';
import { getConfigParam } from '../../../utils';
var analysisOptions = [];
var assetsOptions = [{
  id: 'quilt_return_period',
  title: 'Asset-Quilt Return Period',
  type: 'dropdown',
  data: [{
    id: 'no_asset_quilt',
    label: 'No Asset Quilt',
    value: 'no_asset_quilt'
  }, {
    id: 'weekly',
    label: 'Weekly',
    value: 'weekly'
  }, {
    id: 'monthly',
    label: 'Monthly',
    value: 'monthly'
  }, {
    id: 'quarterly',
    label: 'Quarterly',
    value: 'quarterly'
  }, {
    id: 'yearly',
    label: 'Yearly',
    value: 'yearly'
  }]
}];
var configMap = {
  'charting-tools': [{
    data: assetsOptions,
    defaultState: {
      quilt_return_period: 'monthly'
    },
    machineControlName: 'quilt',
    title: 'Options'
  }],
  'portfolio-analysis': [{
    defaultState: {
      backfill_method: 'cash',
      observation_frequency: 'daily',
      rebalancing_frequency: 'monthly'
    },
    machineControlName: 'general',
    title: 'General Options'
  }, {
    machineControlName: 'tax_options',
    title: 'Tax Options',
    defaultState: {
      expected_te_reduction: 25,
      include_custom_te_scenario: true,
      lt_tax_rate: 15,
      st_tax_rate: 24,
      ta_max_cash: 10000,
      ta_max_num_lots_removal: 0,
      tlh_param_value_pct: 0,
      tlh_param_value_notional: 0,
      trade_whole_lots: false,
      trade_size: 25,
      chart_type: 'tax_impact'
    }
  }, {
    defaultState: {
      asset_measurement_method: 'period_average',
      fee_payment_frequency: 'monthly'
    },
    machineControlName: 'fees',
    title: 'Fees Options'
  }, {
    data: analysisOptions,
    machineControlName: 'analysis_options',
    title: 'Analysis Options'
  }],
  'side-by-side': [{
    defaultState: {
      observation_frequency: 'daily',
      rebalancing_frequency: 'monthly'
    },
    machineControlName: 'general',
    title: 'General Options'
  }]
};
export var feesConfig = {
  fee_amount: {
    helperTitle: 'Management Fee is',
    helperSuffix: '%',
    id: 'fee_amount',
    title: 'Amount, %:',
    type: 'input'
  },
  fee_payment_frequency: {
    helperTitle: 'paid',
    id: 'fee_payment_frequency',
    title: 'Fee Payment Frequency',
    type: 'radio',
    options: [{
      label: 'Monthly',
      value: 'monthly'
    }, {
      label: 'Quarterly',
      value: 'quarterly'
    }, {
      label: 'Annually',
      value: 'annually'
    }]
  },
  asset_measurement_method: {
    helperTitle: 'with',
    helperPlainText: 'used to assess amount.',
    id: 'asset_measurement_method',
    title: 'Asset Measurement Method',
    type: 'radio',
    options: [{
      helperLabel: 'Average AUMs over the period',
      label: 'Period Average',
      value: 'period_average'
    }, {
      helperLabel: 'AUMs at the end of the period',
      label: 'Period End',
      value: 'period_end'
    }]
  }
};
export var generalConfig = {
  start_date: {
    helperTitle: 'Start Date is',
    id: 'start_date',
    title: 'Start Date',
    type: 'date',
    value: moment(moment().subtract(3, 'months')).startOf('month')._d
  },
  end_date: {
    helperTitle: 'End Date is',
    id: 'end_date',
    title: 'End Date',
    type: 'date',
    value: moment(moment().subtract(1, 'months')).endOf('month')._d
  },
  cash_return: {
    helperTitle: 'Cash Return is',
    helperSuffix: '%',
    id: 'cash_return',
    title: 'Cash Return, %:',
    type: 'input',
    value: 0
  },
  backfill_method: {
    helperTitle: 'Backfill Method is',
    id: 'backfill_method',
    title: 'Backfill Method',
    type: 'radio',
    options: [{
      label: 'Cash',
      value: 'cash'
    }, {
      label: 'No Backfill (Common Inception)',
      value: 'common_inception'
    }]
  },
  observation_frequency: {
    helperTitle: 'Observation Frequency is',
    id: 'observation_frequency',
    title: 'Observation Frequency',
    type: 'radio',
    options: [{
      label: 'Daily',
      value: 'daily'
    }, {
      label: 'Monthly',
      value: 'monthly'
    }]
  },
  rebalancing_frequency: {
    helperTitle: 'Rebalancing Frequency is',
    id: 'rebalancing_frequency',
    title: 'Rebalancing Frequency',
    type: 'radio',
    options: [{
      label: 'Buy & Hold',
      value: 'buy_and_hold'
    }, {
      label: 'Monthly',
      value: 'monthly'
    }, {
      label: 'Quarterly',
      value: 'quarterly'
    }, {
      label: 'Annually',
      value: 'annually'
    }]
  }
};
export var taxConfig = {
  st_tax_rate: {
    id: 'st_tax_rate',
    helperTitle: 'Tax Rates: Short Term, % is',
    title: 'Short Term, %:',
    type: 'input'
  },
  lt_tax_rate: {
    id: 'lt_tax_rate',
    helperTitle: 'Tax Rates: Long Term, % is',
    title: 'Long Term, %:',
    type: 'input'
  },
  tlh_param_value_pct: {
    id: 'tlh_param_value_pct',
    helperTitle: 'TLH: Deviation, % is',
    title: 'Deviation, %:',
    type: 'input'
  },
  tlh_param_value_notional: {
    id: 'tlh_param_value_notional',
    helperTitle: 'TLH: Open losses, $ is',
    title: 'Open losses, $:',
    type: 'input'
  },
  trade_whole_lots: {
    id: 'trade_whole_lots',
    helperTitle: 'Trades: Trade whole lots is',
    title: 'Trade whole lots:',
    type: 'toggle',
    options: [{
      value: false,
      label: 'No'
    }, {
      value: true,
      label: 'Yes'
    }]
  },
  trade_size: {
    id: 'trade_size',
    helperTitle: 'Trades: Trades Size is',
    title: 'Trades Size:',
    type: 'input'
  },
  chart_type: {
    id: 'chart_type',
    helperTitle: 'Transition Graphs is',
    title: 'Transition Graphs',
    type: 'radio',
    options: [{
      label: 'Tax Impact as a function of Turnover',
      value: 'tax_impact'
    }, {
      label: 'Gains Taken as a function of Turnover',
      value: 'gains_taken'
    }]
  },
  include_custom_te_scenario: {
    id: 'include_custom_te_scenario',
    helperTitle: 'Transition Strategies: Add a Cost of Tracking Error Scenario is',
    title: 'Add a Cost of Tracking Error Scenario:',
    type: 'toggle',
    options: [{
      value: false,
      label: 'No'
    }, {
      value: true,
      label: 'Yes'
    }]
  },
  expected_te_reduction: {
    id: 'expected_te_reduction',
    helperTitle: 'Transition Strategies: Expected Tracking Error improvement, % is',
    title: 'Expected Tracking Error improvement, %:',
    type: 'input'
  },
  ta_max_cash: {
    id: 'ta_max_cash',
    helperTitle: 'Funding Requirements: Maximum Cash Amount Callable, $ is',
    title: 'Maximum Cash Amount Callable, $:',
    type: 'input'
  },
  ta_max_num_lots_removal: {
    id: 'ta_max_num_lots_removal',
    helperTitle: 'Funding Requirements: Maximum Tax Lot Exclusion is',
    title: 'Maximum Tax Lot Exclusion:',
    type: 'input'
  }
};
export var getConfig = function getConfig(type) {
  var config = configMap[type].filter(function (option) {
    return !(getConfigParam('env') === 'production' && option.hideOnProd) || !option.hideOnProd;
  });
  return config;
};
export var tooltips = {
  date_range: 'Sets the start date and end date of the backtested historical performance. If these options are not set, the simulation will attempt to go as far back in history as possible.',
  cash_return: 'Sets the annual interest rate earned on any cash in the portfolio. The default is 0%.',
  backfill_method: 'Timeseries history may begin at different times. Common inception sets the start date of the backtest to the earliest date for which there is price history for all portfolio items. Cash backfill will replace portfolio items with cash until the portfolio has 50% cash, at which point the backtest start date is set.',
  observation_frequency: 'Determines whether the portfolio backtest uses daily or end-of-month prices.',
  rebalancing_frequency: 'Portfolios are rebalanced considering provided portfolio history as well as selected rebalancing frequency. The default is to reset the portfolio to the selected weights at the end of each month.',
  fee_amount: 'The annual management fee. The default is 0%.',
  fee_payment_frequency: 'Set the frequency at which the chosen management fee amount is withdrawn from the portfolio.',
  asset_measurement_method: 'Determines how assets are calculated for the purpose of calculating the management fee. For example, setting a Quarterly payment frequency based on the average AUM over the period will compute the average assets in the backtested portfolio.',
  overview: 'Standard options will provide a price-basked backtest and a net-holdings look through on fund exposures.',
  performance: 'Performance attribution will analyze performance against the benchmark. The position quilt will show asset quilt returns for the individual positions in your portfolio over time.',
  risk: 'Risk will look at the volatility of your portfolio and highlight which components are driving risk.',
  cost: 'Cost will analyze fund holdings management fees.',
  'risk vs perf': 'Efficient frontier calculations will run a portfolio optimization to show whether your portfolio is risk or return optimized.',
  factors: 'Provides a factor analysis and scenario testing on your portfolio.',
  tax: "Review your portfolio's tax situation. If tax lots are provided in the input portfolio, they will be included in the analysis. Tax analysis is sensitive to the as-of date of the input portfolios for calculating or simulating cost-basis.",
  esg: 'Look through to underlying equity holdings and highlight ESG warning flags.',
  income: "Calculate your portfolio's income distribution based on trailing and estimated dividends.",
  quilt_return_period: 'Sets the performance period for the asset quilt returns.',
  tax_rates: 'Sets the values of the Tax Rates to be used when assessing Tax Assets/Liabilities associated with the holdings in the portfolio.',
  tax_loss_harvesting: 'Defines the heuristic used to identify Tax Loss Harvesting Opportunities in the portfolio of interest.',
  trades: 'When performing the Transition Analysis, smoother results can be achieved by splitting the existing lots. In practice, any lot with a size smaller than the defined Trades Size value will be kept as-is, larger ones will be broken down to allow for more granular transitions.',
  chart_type: 'Specifies whether to show the Tax Assets/Liabilities or the Gains generated during the transition as a function of implied portfolio turnover.',
  include_custom_te_scenario: 'Specifies whether to clearly identify a customized scenario on the Transition curves. This scenario links the expected improvement in Tracking Error with the associated Tax Assets/Liabilities.',
  ta_funding_requirements: 'Funding Requirement settings for callable cash thresholds and tax lot exclusions'
};
/* eslint-disable import/prefer-default-export */
import { useState, useEffect } from 'react';
export var useWindowSize = function useWindowSize() {
  var _useState = useState({
      width: undefined,
      height: undefined
    }),
    windowSize = _useState[0],
    setWindowSize = _useState[1];
  useEffect(function () {
    var handleResize = function handleResize() {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight
      });
    };
    window.addEventListener('resize', handleResize);
    handleResize();
    return function () {
      return window.removeEventListener('resize', handleResize);
    };
  }, []);
  return windowSize;
};
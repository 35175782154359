import breakpoints from '../../../shared/data/breakpoints.json';
import { getRandomFromRange } from '../../utils';
export var ARRAY_FORMAT = {
  arrayFormat: 'comma'
};
export var CHECKBOX_FILTERS = [{
  column: 1,
  label: 'Equity',
  name: 'equity'
}, {
  column: 1,
  label: 'Fixed Income',
  name: 'fixed-icome'
}, {
  column: 1,
  label: 'Commodities',
  name: 'commodities'
}, {
  column: 2,
  label: 'Currency',
  name: 'currency'
}, {
  column: 2,
  label: 'Alternative',
  name: 'alternative'
}, {
  column: 2,
  label: 'Mixed',
  name: 'mixed'
}, {
  column: 3,
  label: 'Leveraged',
  name: 'leveraged'
}];
export var CURRENT_LOCALE = {
  code: 'World',
  name: 'World'
};
export var DEFAULT_QUERY_STRING = {
  querystring: 'metric=returns&sections=chart-bar&time_range=1w&underlyingassettype=Equity'
};
export var DURATION_FILTERS = [{
  id: '1-week',
  label: '1 Week',
  value: '1w'
}, {
  id: '1-month',
  label: '1 Month',
  value: '1m'
}, {
  id: '1-year',
  label: 'YTD',
  value: 'ytd'
}];
export var getTrendItemBars = function getTrendItemBars() {
  return [getRandomFromRange(80, 101), getRandomFromRange(50, 80), getRandomFromRange(20, 50), getRandomFromRange(20, 50), getRandomFromRange(80, 90), getRandomFromRange(90, 101)];
};
export var NEXT_SHELF = {
  icon: ['trend-arrow', 'trend-area'],
  iconName: 'trend',
  info: [],
  label: 'Trending',
  name: 'machine',
  notes: [],
  shelf: 'Trending',
  size: 42,
  type: 'static'
};
export var RADIO_TRENDS_FILTERS = [{
  id: '%-returns',
  label: '% Returns',
  value: 'returns'
}, {
  id: 'newest',
  label: 'Newest',
  value: 'newest'
}, {
  id: '%-flows',
  label: '% Flows',
  value: 'flows_pct'
}, {
  id: '$-flows',
  label: "$ " + (window.innerWidth >= breakpoints.viewport.medium ? '' : ' ') + "Flows",
  value: 'flows'
}, {
  id: '%-flows-50',
  label: '% Flows (AUM > 50M)',
  value: 'flows_pct_50'
}];
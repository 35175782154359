var _excluded = ["custom_filter"];
function _objectWithoutPropertiesLoose(source, excluded) { if (source == null) return {}; var target = {}; var sourceKeys = Object.keys(source); var key, i; for (i = 0; i < sourceKeys.length; i++) { key = sourceKeys[i]; if (excluded.indexOf(key) >= 0) continue; target[key] = source[key]; } return target; }
function _extends() { _extends = Object.assign ? Object.assign.bind() : function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; return _extends.apply(this, arguments); }
/* eslint-disable import/prefer-default-export */
import { FILTER_TYPES } from './Filters/useFilter';
import { nonStandardFilters } from './utils';
import { useReducer } from 'react';
import _find from 'lodash/find';
import _omit from 'lodash/omit';
import isEqual from 'lodash.isequal';
export var action_type = {
  CLEAR_ALL: 'clear_all',
  FILTER_CHANGE: 'filter_change',
  FILTER_PUT_CHANGE: 'filter_put_change',
  VIEW_CHANGE: 'view_change'
};
var handleCheckboxChange = function handleCheckboxChange(_ref) {
  var action = _ref.action,
    id = _ref.fieldID,
    state = _ref.state;
  var value = action.data.value;
  if (state[id]) {
    var _state$id;
    if (typeof state[id] === 'string') {
      if (state[id] === value) return [];
      return [state[id], value];
    }
    if ((_state$id = state[id]) != null && _state$id.some(function (v) {
      return v === value;
    })) {
      return state[id].filter(function (v) {
        return v !== value;
      });
    }
  }
  return [].concat(state[id], [value]).filter(function (v) {
    return v;
  });
};
var handleFilterChange = function handleFilterChange(state, action) {
  var _action$data, _action$data2;
  var fieldID = (action == null || (_action$data = action.data) == null ? void 0 : _action$data.parent) + "^" + (action == null || (_action$data2 = action.data) == null ? void 0 : _action$data2.id);
  switch (action.inputType) {
    case FILTER_TYPES.CHECK:
      {
        var _extends2;
        var data = handleCheckboxChange({
          action: action,
          fieldID: fieldID,
          state: state
        });
        if (data.length === 0) {
          delete state[fieldID];
          return _extends({}, state);
        }
        return _extends({}, state, (_extends2 = {}, _extends2[fieldID] = handleCheckboxChange({
          action: action,
          fieldID: fieldID,
          state: state
        }), _extends2));
      }
    case FILTER_TYPES.LINKS_WATCHLIST:
      {
        var _data = handleWatchlistChange({
          action: action,
          state: state
        });
        if (_data === null) {
          delete state.watchlist;
          return _extends({}, state);
        }
        return _extends({}, state, {
          watchlist: _data
        });
      }
    case FILTER_TYPES.RADIO:
      return _extends({}, handleRadioChange({
        action: action,
        fieldID: fieldID,
        state: state
      }));
    case FILTER_TYPES.DATE_RANGE:
    case FILTER_TYPES.SLIDER:
      {
        return _extends({}, handleRangeChange({
          action: action,
          fieldID: fieldID,
          state: state
        }));
      }
    case FILTER_TYPES.TEXTBOX:
      {
        var keyId = action.data.id;
        if (typeof action.data.value === 'string' && action.data.value) {
          var _extends3;
          return _extends({}, state, (_extends3 = {}, _extends3[keyId] = action.data.value, _extends3));
        }
        delete state[keyId];
        return _extends({}, state);
      }
    default:
      console.error('Unknow input type', 'action:', action);
      return state;
  }
};
var handleRadioChange = function handleRadioChange(_ref2) {
  var _extends4;
  var action = _ref2.action,
    id = _ref2.fieldID,
    state = _ref2.state;
  var value = action.data.value;
  if (state[id] === value) {
    delete state[id];
    return state;
  }
  return _extends({}, state, (_extends4 = {}, _extends4[id] = value, _extends4));
};
var handleRangeChange = function handleRangeChange(_ref3) {
  var _extends5;
  var action = _ref3.action,
    id = _ref3.fieldID,
    state = _ref3.state;
  var _action$data3 = action.data,
    defaultValue = _action$data3.defaultValue,
    value = _action$data3.value;
  if (isEqual(defaultValue, value)) {
    delete state[id];
    return state;
  }
  return _extends({}, state, (_extends5 = {}, _extends5[id] = value, _extends5));
};
var handleWatchlistChange = function handleWatchlistChange(_ref4) {
  var action = _ref4.action,
    state = _ref4.state;
  var watchlist = action.data.watchlist;
  if (state.watchlist === watchlist) return null;
  return watchlist;
};
var CLEAR_ALL = action_type.CLEAR_ALL,
  FILTER_CHANGE = action_type.FILTER_CHANGE,
  FILTER_PUT_CHANGE = action_type.FILTER_PUT_CHANGE,
  VIEW_CHANGE = action_type.VIEW_CHANGE;
var reducer = function reducer(state, action) {
  if (action.type === VIEW_CHANGE) {
    return _extends({}, state, {
      view: action.data
    });
  }
  if (action.type === FILTER_PUT_CHANGE) {
    return _extends({}, action.data, state.view && {
      view: state.view
    });
  }
  if (action.type === CLEAR_ALL) {
    var customClear = _find(nonStandardFilters, {
      label: action.data
    });
    if (customClear != null && customClear.value) return _omit(state, customClear.value);
    var clearedStateKeys = Object.keys(state).filter(function (key) {
      return !key.includes(action.data + "^");
    });
    var clearedState = Object.entries(state).filter(function (filter) {
      var validKey = clearedStateKeys.filter(function (clearedKey) {
        return clearedKey === filter[0];
      });
      if (validKey.length !== 0) return true;
      return false;
    });
    var newClearedState = Object.fromEntries(clearedState);
    return newClearedState;
  }
  if (action.type === FILTER_CHANGE) {
    var _state = _extends({}, state),
      custom_filter = _state.custom_filter,
      rest = _objectWithoutPropertiesLoose(_state, _excluded);
    return handleFilterChange(rest, action);
  }
  console.error('Missing action type:', action);
  return state;
};
export var useFilterStateReducer = function useFilterStateReducer() {
  var _useReducer = useReducer(reducer, {}),
    state = _useReducer[0],
    dispatch = _useReducer[1];
  return [state, dispatch];
};
import { useSelector } from 'react-redux';
var useSelectors = function useSelectors() {
  var cabinetMode = useSelector(function (_ref) {
    var cabinetMode = _ref.cabinetMode;
    return cabinetMode;
  });
  var cabinetNav = useSelector(function (_ref2) {
    var cabinetNav = _ref2.cabinetNav;
    return cabinetNav;
  });
  var cards = useSelector(function (_ref3) {
    var cards = _ref3.cards;
    return cards;
  });
  return {
    cabinetMode: cabinetMode,
    cabinetNav: cabinetNav,
    cards: cards
  };
};
export default useSelectors;
function _extends() { _extends = Object.assign ? Object.assign.bind() : function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; return _extends.apply(this, arguments); }
import * as Sentry from '@sentry/react';
import { Auth } from 'aws-amplify';
import { combineReducers } from 'redux';
import { getBasicReducer, getConfigParam, isLocalStorage } from '../utils';
import { CLEAR_DEFAULT_PORTFOLIO, CLEAR_TOASTS, PORTFOLIO_WIDGET_MODAL_CHANGES, SET_ADD_TO_WATCHLIST, SET_ADVANCED_MODAL, SET_AUTH_CLOSE_OVERLAY, SET_AUTH_EVENT, SET_AUTH_INFO, SET_AXOS_FLAG, SET_CABINET_MODE, SET_CABINET_NAV, SET_CARDS_BUCKET, SET_CARDS, SET_COACH_MONITORING, SET_COACH_PORTFOLIOS, SET_COACH_SYNC_LIST, SET_CREATE_USER_FROM_DASHBOARD, SET_CREATE_WATCHLIST, SET_CURRENT_CUSTOM_VIEW, SET_CURRENT_SHELF, SET_CURRENT_USER, SET_CUSTOM_FILTERS, SET_CUSTOM_VIEWS, SET_DEFAULT_BENCHMARK, SET_DEFAULT_CONTROLS, SET_DEFAULT_ROWS_COUNT, SET_DELETE_WATCHLIST_ITEM, SET_DISPLAY_SECURITIES, SET_DYNAMIC_SECTIONS, SET_EMAIL_ALERTS, SET_WATCHLISTS, SET_INTEGRATION_PROCESS_QUEUE, SET_INTEGRATIONS, SET_INTEGRATIONS_LOADER, SET_JUMP_LINKS, SET_JUMP_TO_SECTION, SET_LANDING_TRENDS, SET_LANDING_TRENDS_LIST, SET_LIGHT_MODE, SET_LOGIN_2FA, SET_MACHINE, SET_MACHINE_APPLY, SET_MACHINE_CONTEXT, SET_MASQ_MODE, SET_MACHINE_REQUEST, SET_NAVIGATION, SET_NAVIGATION_LOADING, SET_NETWORK_ERROR, SET_OPEN_OVERLAY, SET_OPEN_WALKTHROUGH, SET_ORDERED_REPORT_SECTIONS, SET_PAGINATION, SET_PORTFOLIOS, SET_REACT_TABLE_ACTION, SET_PREV_MACHINE_PARAMS, SET_PRIMARY_PORTFOLIO, SET_REPORT_LOADING, SET_REPORT_PREFERENCES, SET_SCROLLBAR_OFFSET, SET_SEARCHBAR_MODE, SET_SECONDARY_PORTFOLIO, SET_SHELF_MODE, SET_SIDEBAR_MODE, SET_STARRED_ITEM, SET_TAB_VIEW, SET_TABLE_DOWNLOADING, SET_TOAST, SET_TOASTS_POSITION, SET_TOGGLE_PLAID_CONFIG, SET_TRANSITION_BUTTON_VISIBLE, SET_UNSAVED_PW_CHANGES, SET_UPDATE_USER_TIER, SET_USER_DETAILS, SET_USERS, SET_WIDGET_MODE, SET_WINDOW_WIDTH, SET_WORLD_MODE, SET_QUILT_TIME_PERIOD } from './actions';
import qs from 'query-string';
var addToWatchlistResp = function addToWatchlistResp(state, action) {
  if (state === void 0) {
    state = null;
  }
  return getBasicReducer({
    action: action,
    name: SET_ADD_TO_WATCHLIST,
    state: state
  });
};
var portfolioListAction = function portfolioListAction(state, action) {
  if (state === void 0) {
    state = null;
  }
  return getBasicReducer({
    action: action,
    name: SET_REACT_TABLE_ACTION,
    state: state
  });
};
var advancedModal = function advancedModal(state, action) {
  if (state === void 0) {
    state = {};
  }
  return getBasicReducer({
    action: action,
    name: SET_ADVANCED_MODAL,
    state: state
  });
};
var authCloseOverlay = function authCloseOverlay(state, action) {
  if (state === void 0) {
    state = null;
  }
  return getBasicReducer({
    action: action,
    name: SET_AUTH_CLOSE_OVERLAY,
    state: state
  });
};
var authEvent = function authEvent(state, action) {
  if (state === void 0) {
    state = 0;
  }
  return getBasicReducer({
    action: action,
    name: SET_AUTH_EVENT,
    state: state
  });
};
var authInfo = function authInfo(state, action) {
  if (state === void 0) {
    state = {};
  }
  return getBasicReducer({
    action: action,
    name: SET_AUTH_INFO,
    state: state
  });
};
var axosMode = function axosMode(state, action) {
  if (state === void 0) {
    state = false;
  }
  return getBasicReducer({
    action: action,
    name: SET_AXOS_FLAG,
    state: state
  });
};
var cabinetMode = function cabinetMode(state, action) {
  if (state === void 0) {
    state = false;
  }
  return getBasicReducer({
    action: action,
    name: SET_CABINET_MODE,
    state: state
  });
};
var cabinetNav = function cabinetNav(state, action) {
  if (state === void 0) {
    state = null;
  }
  return getBasicReducer({
    action: action,
    name: SET_CABINET_NAV,
    state: state
  });
};
var cards = function cards(state, action) {
  if (state === void 0) {
    state = {};
  }
  return getBasicReducer({
    action: action,
    name: SET_CARDS,
    state: state
  });
};
var cardsBucket = function cardsBucket(state, action) {
  if (state === void 0) {
    state = {};
  }
  return getBasicReducer({
    action: action,
    name: SET_CARDS_BUCKET,
    state: state
  });
};
var clearDefaultPortfolio = function clearDefaultPortfolio(state, action) {
  if (state === void 0) {
    state = {};
  }
  return getBasicReducer({
    action: action,
    name: CLEAR_DEFAULT_PORTFOLIO,
    state: state
  });
};
var coachMonitoring = function coachMonitoring(state, action) {
  if (state === void 0) {
    state = 0;
  }
  return getBasicReducer({
    action: action,
    name: SET_COACH_MONITORING,
    state: state
  });
};
var coachPortfolios = function coachPortfolios(state, action) {
  if (state === void 0) {
    state = {};
  }
  return getBasicReducer({
    action: action,
    name: SET_COACH_PORTFOLIOS,
    state: state
  });
};
var currentCustomView = function currentCustomView(state, action) {
  if (state === void 0) {
    state = {};
  }
  return getBasicReducer({
    action: action,
    name: SET_CURRENT_CUSTOM_VIEW,
    state: state
  });
};
var currentShelf = function currentShelf(state, action) {
  if (state === void 0) {
    state = {};
  }
  return getBasicReducer({
    action: action,
    name: SET_CURRENT_SHELF,
    state: state
  });
};
var currentUser = function currentUser(state, action) {
  if (state === void 0) {
    state = {};
  }
  return getBasicReducer({
    action: action,
    name: SET_CURRENT_USER,
    state: state
  });
};
var customFilters = function customFilters(state, action) {
  if (state === void 0) {
    state = [];
  }
  return getBasicReducer({
    action: action,
    name: SET_CUSTOM_FILTERS,
    state: state
  });
};
var customViews = function customViews(state, action) {
  if (state === void 0) {
    state = null;
  }
  return getBasicReducer({
    action: action,
    name: SET_CUSTOM_VIEWS,
    state: state
  });
};
var createUserFromDashboardResp = function createUserFromDashboardResp(state, action) {
  if (state === void 0) {
    state = {};
  }
  return getBasicReducer({
    action: action,
    name: SET_CREATE_USER_FROM_DASHBOARD,
    state: state
  });
};
var createWatchlistResp = function createWatchlistResp(state, action) {
  if (state === void 0) {
    state = null;
  }
  return getBasicReducer({
    action: action,
    name: SET_CREATE_WATCHLIST,
    state: state
  });
};
var defaultBenchmark = function defaultBenchmark(state, action) {
  if (state === void 0) {
    state = {};
  }
  return getBasicReducer({
    action: action,
    name: SET_DEFAULT_BENCHMARK,
    state: state
  });
};
var defaultControls = function defaultControls(state, action) {
  if (state === void 0) {
    state = false;
  }
  return getBasicReducer({
    action: action,
    name: SET_DEFAULT_CONTROLS,
    state: state
  });
};
var defaultRowsCount = function defaultRowsCount(state, action) {
  if (state === void 0) {
    state = 20;
  }
  return getBasicReducer({
    action: action,
    name: SET_DEFAULT_ROWS_COUNT,
    state: state
  });
};
var deleteWatchlistItemResp = function deleteWatchlistItemResp(state, action) {
  if (state === void 0) {
    state = null;
  }
  return getBasicReducer({
    action: action,
    name: SET_DELETE_WATCHLIST_ITEM,
    state: state
  });
};
var isTransitionAnalysisAvailable = function isTransitionAnalysisAvailable(state, action) {
  if (state === void 0) {
    state = false;
  }
  return getBasicReducer({
    action: action,
    name: SET_TRANSITION_BUTTON_VISIBLE,
    state: state
  });
};
var dynamicSections = function dynamicSections(state, action) {
  if (state === void 0) {
    state = {};
  }
  return getBasicReducer({
    action: action,
    name: SET_DYNAMIC_SECTIONS,
    state: state
  });
};
var tableDownloading = function tableDownloading(state, action) {
  if (state === void 0) {
    state = false;
  }
  return getBasicReducer({
    action: action,
    name: SET_TABLE_DOWNLOADING,
    state: state
  });
};
var tabView = function tabView(state, action) {
  if (state === void 0) {
    state = 0;
  }
  return getBasicReducer({
    action: action,
    name: SET_TAB_VIEW,
    state: state
  });
};
var integrationProcessQueue = function integrationProcessQueue(state, action) {
  if (state === void 0) {
    state = [];
  }
  return getBasicReducer({
    action: action,
    name: SET_INTEGRATION_PROCESS_QUEUE,
    state: state
  });
};
var integrations = function integrations(state, action) {
  if (state === void 0) {
    state = null;
  }
  return getBasicReducer({
    action: action,
    name: SET_INTEGRATIONS,
    state: state
  });
};
var integrationsLoader = function integrationsLoader(state, action) {
  if (state === void 0) {
    state = {};
  }
  return getBasicReducer({
    action: action,
    name: SET_INTEGRATIONS_LOADER,
    state: state
  });
};
var isMachineRequest = function isMachineRequest(state, action) {
  if (state === void 0) {
    state = false;
  }
  return getBasicReducer({
    action: action,
    name: SET_MACHINE_REQUEST,
    state: state
  });
};
var jumpLinks = function jumpLinks(state, action) {
  if (state === void 0) {
    state = [];
  }
  return getBasicReducer({
    action: action,
    name: SET_JUMP_LINKS,
    state: state
  });
};
var jumpToSection = function jumpToSection(state, action) {
  if (state === void 0) {
    state = '';
  }
  return getBasicReducer({
    action: action,
    name: SET_JUMP_TO_SECTION,
    state: state
  });
};
var landingTrends = function landingTrends(state, action) {
  if (state === void 0) {
    state = {};
  }
  return getBasicReducer({
    action: action,
    name: SET_LANDING_TRENDS,
    state: state
  });
};
var landingTrendsList = function landingTrendsList(state, action) {
  if (state === void 0) {
    state = {};
  }
  return getBasicReducer({
    action: action,
    name: SET_LANDING_TRENDS_LIST,
    state: state
  });
};
var logIn2FA = function logIn2FA(state, action) {
  if (state === void 0) {
    state = {
      displayForm: false,
      status: 'idle'
    };
  }
  return getBasicReducer({
    action: action,
    name: SET_LOGIN_2FA,
    state: state
  });
};
var lightMode = function lightMode(state, action) {
  var _window;
  if (state === void 0) {
    state = false;
  }
  var queryParams = qs.parse((_window = window) == null || (_window = _window.location) == null ? void 0 : _window.search);
  if (!queryParams.widget && isLocalStorage(function () {
    return localStorage;
  })) {
    state = JSON.parse(window.localStorage.getItem('isLightMode'));
  }
  return getBasicReducer({
    action: action,
    name: SET_LIGHT_MODE,
    state: state
  });
};
var listEmailAlerts = function listEmailAlerts(state, action) {
  if (state === void 0) {
    state = [];
  }
  return getBasicReducer({
    action: action,
    name: SET_EMAIL_ALERTS,
    state: state
  });
};
var machineContext = function machineContext(state, action) {
  if (state === void 0) {
    state = null;
  }
  var newAction = action;
  if (action.type === SET_MACHINE) {
    if (action.payload && action.payload.machineContext) {
      newAction = {
        payload: action.payload.machineContext,
        type: SET_MACHINE_CONTEXT
      };
    } else {
      newAction = {
        payload: null,
        type: SET_MACHINE_CONTEXT
      };
    }
  }
  return getBasicReducer({
    action: newAction,
    name: SET_MACHINE_CONTEXT,
    state: state
  });
};
var machine = function machine(state, action) {
  if (state === void 0) {
    state = {};
  }
  var newAction = action;
  if (action.type === SET_MACHINE) {
    if (action.payload && action.payload.machineContext) {
      newAction = {
        payload: action.payload.machine,
        type: action.type
      };
    }
  }
  return getBasicReducer({
    action: newAction,
    name: SET_MACHINE,
    state: state
  });
};
var machineApply = function machineApply(state, action) {
  if (state === void 0) {
    state = false;
  }
  return getBasicReducer({
    action: action,
    name: SET_MACHINE_APPLY,
    state: state
  });
};
var masqMode = function masqMode(state, action) {
  if (state === void 0) {
    state = {};
  }
  return getBasicReducer({
    action: action,
    name: SET_MASQ_MODE,
    state: state
  });
};
var navigation = function navigation(state, action) {
  if (state === void 0) {
    state = {};
  }
  return getBasicReducer({
    action: action,
    name: SET_NAVIGATION,
    state: state
  });
};
var navigationLoading = function navigationLoading(state, action) {
  if (state === void 0) {
    state = false;
  }
  return getBasicReducer({
    action: action,
    name: SET_NAVIGATION_LOADING,
    state: state
  });
};
var networkError = function networkError(state, action) {
  if (state === void 0) {
    state = false;
  }
  return getBasicReducer({
    action: action,
    name: SET_NETWORK_ERROR,
    state: state
  });
};
var openOverlay = function openOverlay(state, action) {
  if (state === void 0) {
    state = {};
  }
  return getBasicReducer({
    action: action,
    name: SET_OPEN_OVERLAY,
    state: state
  });
};
var openWalkthrough = function openWalkthrough(state, action) {
  if (state === void 0) {
    state = false;
  }
  return getBasicReducer({
    action: action,
    name: SET_OPEN_WALKTHROUGH,
    state: state
  });
};
var orderedReportSections = function orderedReportSections(state, action) {
  if (state === void 0) {
    state = null;
  }
  return getBasicReducer({
    action: action,
    name: SET_ORDERED_REPORT_SECTIONS,
    state: state
  });
};
var pagination = function pagination(state, action) {
  if (state === void 0) {
    state = null;
  }
  return getBasicReducer({
    action: action,
    name: SET_PAGINATION,
    state: state
  });
};
var portfolios = function portfolios(state, action) {
  if (state === void 0) {
    state = {};
  }
  return getBasicReducer({
    action: action,
    name: SET_PORTFOLIOS,
    state: state
  });
};
var pwSaveChanges = function pwSaveChanges(state, action) {
  if (state === void 0) {
    state = false;
  }
  return getBasicReducer({
    action: action,
    name: PORTFOLIO_WIDGET_MODAL_CHANGES,
    state: state
  });
};
var primaryPortfolio = function primaryPortfolio(state, action) {
  if (state === void 0) {
    state = {};
  }
  return getBasicReducer({
    action: action,
    name: SET_PRIMARY_PORTFOLIO,
    state: state
  });
};
var prevMachineParams = function prevMachineParams(state, action) {
  if (state === void 0) {
    state = {};
  }
  return getBasicReducer({
    action: action,
    name: SET_PREV_MACHINE_PARAMS,
    state: state
  });
};
var quiltTimePeriod = function quiltTimePeriod(state, action) {
  if (state === void 0) {
    state = null;
  }
  return getBasicReducer({
    action: action,
    name: SET_QUILT_TIME_PERIOD,
    state: state
  });
};
var regionFilter = function regionFilter(state, action) {
  if (state === void 0) {
    state = null;
  }
  return getBasicReducer({
    action: action,
    name: SET_DISPLAY_SECURITIES,
    state: state
  });
};
var reportLoading = function reportLoading(state, action) {
  if (state === void 0) {
    state = null;
  }
  return getBasicReducer({
    action: action,
    name: SET_REPORT_LOADING,
    state: state
  });
};
var reportPreferences = function reportPreferences(state, action) {
  if (state === void 0) {
    state = {};
  }
  return getBasicReducer({
    action: action,
    name: SET_REPORT_PREFERENCES,
    state: state
  });
};
var scrollOffset = function scrollOffset(state, action) {
  if (state === void 0) {
    state = 0;
  }
  return getBasicReducer({
    action: action,
    name: SET_SCROLLBAR_OFFSET,
    state: state
  });
};
var searchBarMode = function searchBarMode(state, action) {
  if (state === void 0) {
    state = false;
  }
  return getBasicReducer({
    action: action,
    name: SET_SEARCHBAR_MODE,
    state: state
  });
};
var secondaryPortfolio = function secondaryPortfolio(state, action) {
  if (state === void 0) {
    state = {};
  }
  return getBasicReducer({
    action: action,
    name: SET_SECONDARY_PORTFOLIO,
    state: state
  });
};
var shelfMode = function shelfMode(state, action) {
  if (state === void 0) {
    state = {};
  }
  return getBasicReducer({
    action: action,
    name: SET_SHELF_MODE,
    state: state
  });
};
var sideBarMode = function sideBarMode(state, action) {
  if (state === void 0) {
    state = false;
  }
  return getBasicReducer({
    action: action,
    name: SET_SIDEBAR_MODE,
    state: state
  });
};
var starredListItems = function starredListItems(state, action) {
  if (state === void 0) {
    state = [];
  }
  return getBasicReducer({
    action: action,
    name: SET_STARRED_ITEM,
    state: state
  });
};
var coachSyncList = function coachSyncList(state, action) {
  if (state === void 0) {
    state = [];
  }
  return getBasicReducer({
    action: action,
    name: SET_COACH_SYNC_LIST,
    state: state
  });
};
var toasts = function toasts(state, action) {
  if (state === void 0) {
    state = {};
  }
  if (action.type === SET_TOAST) {
    var _extends2;
    var _action$payload = action.payload,
      id = _action$payload.id,
      message = _action$payload.message,
      mode = _action$payload.mode,
      type = _action$payload.type;
    if (type === 'error') {
      var email;
      var userAttribute;
      var errorMsg = message && JSON.stringify(message);
      Auth.currentAuthenticatedUser().then(function (user) {
        email = user.attributes.email;
        userAttribute = user.attributes;
      }).finally(function () {
        Sentry.configureScope(function (scope) {
          scope.setLevel('Error');
          scope.setUser({
            email: email
          });
          scope.setExtra('user', userAttribute);
          Sentry.captureException(new Error(errorMsg));
        });
      });
    }
    if (message === 'reset') {
      var newState = _extends({}, state);
      delete newState[id];
      return newState;
    }
    return _extends({}, state, (_extends2 = {}, _extends2[id] = {
      message: message,
      mode: mode,
      type: type
    }, _extends2));
  }
  if (action.type === CLEAR_TOASTS) return {};
  return state;
};
var toastsPosition = function toastsPosition(state, action) {
  if (state === void 0) {
    state = {};
  }
  return getBasicReducer({
    action: action,
    name: SET_TOASTS_POSITION,
    state: state
  });
};
var togglePlaidConfig = function togglePlaidConfig(state, action) {
  if (state === void 0) {
    state = getConfigParam('env');
  }
  return getBasicReducer({
    action: action,
    name: SET_TOGGLE_PLAID_CONFIG,
    state: state
  });
};
var unsavedPWChanges = function unsavedPWChanges(state, action) {
  if (state === void 0) {
    state = false;
  }
  return getBasicReducer({
    action: action,
    name: SET_UNSAVED_PW_CHANGES,
    state: state
  });
};
var updateUserTier = function updateUserTier(state, action) {
  if (state === void 0) {
    state = null;
  }
  return getBasicReducer({
    action: action,
    name: SET_UPDATE_USER_TIER,
    state: state
  });
};
var userDetail = function userDetail(state, action) {
  if (state === void 0) {
    state = null;
  }
  return getBasicReducer({
    action: action,
    name: SET_USER_DETAILS,
    state: state
  });
};
var users = function users(state, action) {
  if (state === void 0) {
    state = null;
  }
  return getBasicReducer({
    action: action,
    name: SET_USERS,
    state: state
  });
};
var watchlists = function watchlists(state, action) {
  if (state === void 0) {
    state = null;
  }
  return getBasicReducer({
    action: action,
    name: SET_WATCHLISTS,
    state: state
  });
};
var widgetMode = function widgetMode(state, action) {
  if (state === void 0) {
    state = false;
  }
  return getBasicReducer({
    action: action,
    name: SET_WIDGET_MODE,
    state: state
  });
};
var windowWidth = function windowWidth(state, action) {
  if (state === void 0) {
    state = 0;
  }
  return getBasicReducer({
    action: action,
    name: SET_WINDOW_WIDTH,
    state: state
  });
};
var worldMode = function worldMode(state, action) {
  if (state === void 0) {
    state = {};
  }
  return getBasicReducer({
    action: action,
    name: SET_WORLD_MODE,
    state: state
  });
};
var rootReducer = combineReducers({
  addToWatchlistResp: addToWatchlistResp,
  advancedModal: advancedModal,
  authCloseOverlay: authCloseOverlay,
  authEvent: authEvent,
  authInfo: authInfo,
  axosMode: axosMode,
  cabinetMode: cabinetMode,
  cabinetNav: cabinetNav,
  cards: cards,
  cardsBucket: cardsBucket,
  clearDefaultPortfolio: clearDefaultPortfolio,
  coachMonitoring: coachMonitoring,
  coachPortfolios: coachPortfolios,
  coachSyncList: coachSyncList,
  createUserFromDashboardResp: createUserFromDashboardResp,
  createWatchlistResp: createWatchlistResp,
  currentCustomView: currentCustomView,
  currentShelf: currentShelf,
  currentUser: currentUser,
  customFilters: customFilters,
  customViews: customViews,
  defaultBenchmark: defaultBenchmark,
  defaultControls: defaultControls,
  defaultRowsCount: defaultRowsCount,
  deleteWatchlistItemResp: deleteWatchlistItemResp,
  isTransitionAnalysisAvailable: isTransitionAnalysisAvailable,
  dynamicSections: dynamicSections,
  integrationProcessQueue: integrationProcessQueue,
  integrations: integrations,
  integrationsLoader: integrationsLoader,
  isMachineRequest: isMachineRequest,
  jumpLinks: jumpLinks,
  jumpToSection: jumpToSection,
  landingTrends: landingTrends,
  landingTrendsList: landingTrendsList,
  lightMode: lightMode,
  listEmailAlerts: listEmailAlerts,
  logIn2FA: logIn2FA,
  machineContext: machineContext,
  machine: machine,
  machineApply: machineApply,
  masqMode: masqMode,
  navigation: navigation,
  navigationLoading: navigationLoading,
  networkError: networkError,
  openOverlay: openOverlay,
  openWalkthrough: openWalkthrough,
  orderedReportSections: orderedReportSections,
  pagination: pagination,
  portfolios: portfolios,
  portfolioListAction: portfolioListAction,
  pwSaveChanges: pwSaveChanges,
  prevMachineParams: prevMachineParams,
  primaryPortfolio: primaryPortfolio,
  quiltTimePeriod: quiltTimePeriod,
  regionFilter: regionFilter,
  reportLoading: reportLoading,
  reportPreferences: reportPreferences,
  scrollOffset: scrollOffset,
  searchBarMode: searchBarMode,
  secondaryPortfolio: secondaryPortfolio,
  shelfMode: shelfMode,
  sideBarMode: sideBarMode,
  starredListItems: starredListItems,
  tableDownloading: tableDownloading,
  tabView: tabView,
  toasts: toasts,
  toastsPosition: toastsPosition,
  togglePlaidConfig: togglePlaidConfig,
  unsavedPWChanges: unsavedPWChanges,
  updateUserTier: updateUserTier,
  userDetail: userDetail,
  users: users,
  watchlists: watchlists,
  widgetMode: widgetMode,
  windowWidth: windowWidth,
  worldMode: worldMode
});
export default rootReducer;
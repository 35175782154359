import { setOpenWalkthrough } from '../../../redux/actionCreators';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
var useWalkthroughSearchListener = function useWalkthroughSearchListener() {
  var openWalkthrough = useSelector(function (_ref) {
    var openWalkthrough = _ref.openWalkthrough;
    return openWalkthrough;
  });
  var _useLocation = useLocation(),
    search = _useLocation.search;
  var dispatch = useDispatch();
  useEffect(function () {
    closeWalkthrough();
  }, [search]);
  var closeWalkthrough = function closeWalkthrough() {
    if (openWalkthrough != null && openWalkthrough.isOpen) {
      dispatch(setOpenWalkthrough({
        isOpen: false,
        name: ''
      }));
    }
  };
};
export default useWalkthroughSearchListener;
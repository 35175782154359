/* eslint-disable import/prefer-default-export */
import { scrollElement } from '../utils';
import { useEffect } from 'react';
import { useWindowSize } from './useWindowSize';
import breakpoints from '../../shared/data/breakpoints.json';
var large = breakpoints.viewport.large;
export var useScrollToTop = function useScrollToTop(_ref) {
  var name = _ref.name,
    sections = _ref.sections;
  var _useWindowSize = useWindowSize(),
    width = _useWindowSize.width;
  useEffect(function () {
    if (sections != null && sections.length && width >= large && name !== 'compare') {
      var docAnchor = document.getElementById('app-container');
      docAnchor && docAnchor.scrollTop > 0 && scrollElement(docAnchor);
    }
  }, [sections]);
};
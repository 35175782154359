function _extends() { _extends = Object.assign ? Object.assign.bind() : function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; return _extends.apply(this, arguments); }
import { VIEWS } from '../utils';
export var DUPLICATE_TITLES = {
  CUSTOM_VIEW: 'Duplicate as a Custom View',
  REGULAR_VIEW: 'Duplicate Custom View'
};
export var filterViews = function filterViews(_ref) {
  var views = _ref.views,
    searchValue = _ref.searchValue;
  return function () {
    if (!views) return [];
    if (!searchValue) {
      return views.map(function (view) {
        if ([VIEWS.OVERVIEW].includes(view.view_value)) {
          view.isDragPrevented = true;
        } else {
          delete view.isDragPrevented;
        }
        return view;
      });
    }
    var newFilteredViews = views.reduce(function (acc, view) {
      if (view.view_name.toLowerCase().includes(searchValue.toLowerCase().trim())) return [].concat(acc, [_extends({}, view, {
        isDragPrevented: true
      })]);
      return acc;
    }, []);
    return newFilteredViews;
  };
};
export var formatAvViews = function formatAvViews(avViews, selectedViews) {
  var avViewsWithoutSelectedViews = avViews == null ? void 0 : avViews.filter(function (avView) {
    var avViewExistsInSelectedViews = selectedViews.find(function (selectedView) {
      return selectedView.view_value === avView.view_value;
    });
    if (avViewExistsInSelectedViews) return false;
    return true;
  });
  var formattedAvViews = avViewsWithoutSelectedViews == null ? void 0 : avViewsWithoutSelectedViews.map(function (view) {
    return _extends({}, view, {
      isDisabled: true
    });
  });
  return formattedAvViews || [];
};
export var formatCustomViews = function formatCustomViews(customViews, selectedViews) {
  var customViewsWithoutSelectedViews = customViews.filter(function (customView) {
    var customViewExistsInSelectedViews = selectedViews.find(function (selected_view) {
      return selected_view.view_value === customView.id;
    });
    if (customViewExistsInSelectedViews) return false;
    return true;
  });
  var formattedCustomViews = customViewsWithoutSelectedViews.map(function (view) {
    return _extends({}, view, {
      isCustom: true,
      isDisabled: true,
      view_name: view.name,
      view_value: view.id
    });
  });
  return formattedCustomViews;
};
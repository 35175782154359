import { setToast } from '../../../redux/actionCreators';
import { v4 as uuidv4 } from 'uuid';
import _cloneDeep from 'lodash/cloneDeep';
var MIGRATION_COMPLETED = ['broker', 'eq-screener', 'etf-fact-sheet', 'investor-flow-data', 'portfolios', 'screener', 'watchlists'];
export var VIEWS = {
  OVERVIEW: 'overview'
};
export var PAGE_INPUT_CHANGE_DELAY = 600;
export var checkScreener2023 = function checkScreener2023() {
  return MIGRATION_COMPLETED.some(function (v) {
    return v === location.pathname.substring(1);
  });
};
export var createToast = function createToast(_ref) {
  var dispatch = _ref.dispatch,
    msg = _ref.msg;
  dispatch(setToast({
    id: uuidv4(),
    message: msg,
    type: 'error'
  }));
};
export var useDefaultFilters = function useDefaultFilters(initialFilters, view) {
  var defFilters = {
    view: view || VIEWS.OVERVIEW
  };
  var filtersClone = _cloneDeep([].concat(initialFilters));
  filtersClone == null ? void 0 : filtersClone.forEach(function (filterGroup) {
    Array.isArray(filterGroup.data) && filterGroup.data.forEach(function (filterParent) {
      var _filterParent$data;
      if (filterParent != null && (_filterParent$data = filterParent.data) != null && _filterParent$data.some(function (filter) {
        return filter.is_default;
      })) {
        filterParent.data.forEach(function (filter) {
          if (filter.is_default) {
            var name = filterGroup.name + "^" + filterParent.id;
            var filterValue = filterParent.type === 'check' ? [].concat(filter.value) : filter.value;
            if (name in defFilters) {
              var _defFilters$name;
              (_defFilters$name = defFilters[name]).push.apply(_defFilters$name, filterValue);
            } else {
              defFilters[name] = filterValue;
            }
          }
        });
      } else if (filterParent.is_default) {
        if (filterParent.type === 'date_range') {
          var _Object$assign;
          var name = filterGroup.name + "^" + filterParent.id;
          var filterValue = ["" + filterParent.min_value_default.replaceAll('.', '-'), "" + filterParent.max_value_default.replaceAll('.', '-')];
          Object.assign(defFilters, (_Object$assign = {}, _Object$assign[name] = filterValue, _Object$assign));
        }
      }
    });
  });
  return defFilters;
};
function _extends() { _extends = Object.assign ? Object.assign.bind() : function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; return _extends.apply(this, arguments); }
import { DISPLAY_SEARCH_ON } from '../../Tables/TableReact/utils';
import { getConfigParam } from '../../../utils';
import _size from 'lodash/size';
export var blockContextMenu = function blockContextMenu(overlay) {
  return function (event) {
    if ((overlay == null ? void 0 : overlay.type) === 'teaser' && getConfigParam('env') === 'production') {
      event.preventDefault();
    }
  };
};
export var getCollapsedStyles = function getCollapsedStyles() {
  var sectionsContainerEl = document.querySelector("[data-name='sections-container']");
  if (sectionsContainerEl) {
    sectionsContainerEl.childNodes.forEach(function (childEl) {
      childEl.style.zIndex = parseInt(childEl.dataset.id);
    });
  }
};
export var getSectionStyles = function getSectionStyles(expandedSections, index) {
  var sectionsContainerEl = document.querySelector("[data-name='sections-container']");
  if (sectionsContainerEl) {
    sectionsContainerEl.childNodes.forEach(function (childEl) {
      var childId = parseInt(childEl.dataset.id);
      var idExpanded = expandedSections.findIndex(function (item) {
        return item === childId;
      });
      var prevSections = expandedSections.filter(function (s) {
        return s < childId;
      });
      if (idExpanded >= 0) {
        var actualPosition = childId + prevSections.length;
        if (!(actualPosition % 2)) actualPosition += 1;
        var lineLevel = actualPosition / 2;
        childEl.style.gridRow = lineLevel + "/" + (lineLevel + 1);
      } else {
        childEl.style.gridRow = 'unset';
      }
      childEl.style.zIndex = index + 1;
    });
  }
};
export var isSearchInputAvailable = function isSearchInputAvailable(sectionData) {
  var _sectionData = _extends({}, sectionData),
    data = _sectionData.data,
    interaction = _sectionData.interaction,
    name = _sectionData.name,
    type = _sectionData.type;
  if (type === 'table-react-group') return false;
  return type === 'table-react' && (name === 'portfolio-list' ? _size(data.rows) > DISPLAY_SEARCH_ON : (interaction == null ? void 0 : interaction.length) > 0 ? name === 'screener-table' : _size(data.rows) > DISPLAY_SEARCH_ON);
};
import { usePreviousValue } from '../../../hooks/usePreviousValue';
import { useSelector } from 'react-redux';
export var prevValues = function prevValues(values) {
  var val = usePreviousValue(values);
  return {
    prevPathname: val == null ? void 0 : val.pathname,
    prevScrollOffset: val == null ? void 0 : val.scrollOffset,
    prevSearch: val == null ? void 0 : val.search,
    prevShelfModeActive: val == null ? void 0 : val.shelfModeActive,
    prevSideBarMode: val == null ? void 0 : val.sideBarMode
  };
};
export var useSelectors = function useSelectors() {
  var scrollOffset = useSelector(function (_ref) {
    var scrollOffset = _ref.scrollOffset;
    return scrollOffset;
  });
  var searchBarActive = useSelector(function (_ref2) {
    var searchBarMode = _ref2.searchBarMode;
    return searchBarMode;
  });
  var sideBarMode = useSelector(function (_ref3) {
    var sideBarMode = _ref3.sideBarMode;
    return sideBarMode;
  });
  var winW = useSelector(function (_ref4) {
    var windowWidth = _ref4.windowWidth;
    return windowWidth;
  });
  var shelfModeActive = useSelector(function (_ref5) {
    var shelfMode = _ref5.shelfMode;
    return !!(shelfMode != null && shelfMode.active);
  });
  return {
    scrollOffset: scrollOffset,
    searchBarActive: searchBarActive,
    sideBarMode: sideBarMode,
    winW: winW,
    shelfModeActive: shelfModeActive
  };
};
export var userSelectorsSearchBar = function userSelectorsSearchBar() {
  var regionPath = useSelector(function (_ref6) {
    var worldMode = _ref6.worldMode;
    return worldMode == null ? void 0 : worldMode.regionPath;
  });
  var winW = useSelector(function (_ref7) {
    var windowWidth = _ref7.windowWidth;
    return windowWidth;
  });
  var searchBarActive = useSelector(function (_ref8) {
    var searchBarMode = _ref8.searchBarMode;
    return searchBarMode;
  });
  return {
    regionPath: regionPath,
    searchBarActive: searchBarActive,
    winW: winW
  };
};
export var useSelectorsNav = function useSelectorsNav() {
  var authEvent = useSelector(function (_ref9) {
    var authEvent = _ref9.authEvent;
    return authEvent;
  });
  var currentUser = useSelector(function (_ref10) {
    var currentUser = _ref10.currentUser;
    return currentUser;
  });
  var unsavedPWChanges = useSelector(function (_ref11) {
    var unsavedPWChanges = _ref11.unsavedPWChanges;
    return unsavedPWChanges;
  });
  var winW = useSelector(function (_ref12) {
    var windowWidth = _ref12.windowWidth;
    return windowWidth;
  });
  return {
    authEvent: authEvent,
    currentUser: currentUser,
    unsavedPWChanges: unsavedPWChanges,
    winW: winW
  };
};
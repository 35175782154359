import _cloneDeep from 'lodash/cloneDeep';
import FilterCheckboxes from './components/FilterCheckboxes';
import FilterDateRange from './components/FilterDateRange';
import FilterGroup from './components/FilterGroup';
import FilterLinks from './components/FilterLinks';
import FilterRadios from './components/FilterRadios';
import FilterSlider from './components/FilterSlider';
import FilterTextBox from './components/FilterTextBox';
import FilterWatchlist from './components/FilterWatchlist';
import qs from 'query-string';
import isEqual from 'lodash/isEqual';
export var BENCHMARK_FILTER_KEY = 'Benchmark^ult_benchmark_provider';
export var GENERAL_IS_PRIMARY_FILTER_KEY = 'General^ult_isprimary';
export var CONTENT_TYPES = {
  FILTER_GROUP: 'filter_group',
  WATCHLIST_LINKS: 'links-watchlist'
};
export var EXCEPTED_FILTER_SEARCH = 'Predefined Filters';
export var FILTER_NESTED_TYPE = {
  CHILD: 'child',
  PARENT: 'parent'
};
export var FILTER_TYPES = {
  CHECK: 'check',
  DATE_RANGE: 'date_range',
  LINKS: 'links',
  LINKS_WATCHLIST: 'links-watchlist',
  RADIO: 'radio',
  SLIDER: 'slider',
  TEXTBOX: 'textbox'
};
export var FILTER_URL_NAME_SEPARATOR = '#';
export var checkIfActiveInState = function checkIfActiveInState(state, id, filterNestedType, defaultFilters) {
  if (state) {
    if (FILTER_NESTED_TYPE.PARENT === filterNestedType) {
      var matchedKey = Object.keys(state).filter(function (key) {
        var splitKey = key.split('^');
        if (splitKey[0] === 'ticker_country') return id === 'Ticker Search';
        if (splitKey[0] === 'ticker_search') return id === 'Symbol Search';
        if (splitKey[0] === 'portfolio_search') return id === 'Search';
        return splitKey[0] === id ? checkIsDefault(key, defaultFilters, state) : false;
      });
      return matchedKey.length > 0;
    }
    if (FILTER_NESTED_TYPE.CHILD === filterNestedType) {
      var _matchedKey = Object.keys(state).find(function (key) {
        var splitKey = key.split('^');
        var setValue = false;
        if (splitKey[0] === 'Flows') {
          setValue = "t_" + splitKey[2] === id ? true : false;
        } else {
          var _splitKey$;
          if ((_splitKey$ = splitKey[1]) != null && _splitKey$.includes(FILTER_URL_NAME_SEPARATOR)) {
            splitKey[1] = splitKey[1].substr(0, splitKey[1].indexOf(FILTER_URL_NAME_SEPARATOR));
          }
          setValue = splitKey[1] === id ? true : false;
        }
        return setValue;
      });
      return _matchedKey ? state[_matchedKey] : null;
    }
  }
};
var checkIsDefault = function checkIsDefault(stateItem, defaultFilters, state) {
  var isDefault = true;
  defaultFilters && Object.keys(defaultFilters).forEach(function (defaultFilter) {
    if (stateItem === defaultFilter) {
      if (isEqual(state[stateItem], defaultFilters[defaultFilter])) {
        isDefault = false;
      }
    }
  });
  return isDefault;
};
export var getCompByFilterContentType = function getCompByFilterContentType(type) {
  switch (type) {
    case CONTENT_TYPES.FILTER_GROUP:
    case CONTENT_TYPES.WATCHLIST_LINKS:
      return FilterGroup;
    default:
      return null;
  }
};

// eslint-disable-next-line react/no-multi-comp
export var getCompByFilterType = function getCompByFilterType(type) {
  switch (type) {
    case FILTER_TYPES.CHECK:
      return FilterCheckboxes;
    case FILTER_TYPES.DATE_RANGE:
      return FilterDateRange;
    case FILTER_TYPES.LINKS:
      return FilterLinks;
    case FILTER_TYPES.LINKS_WATCHLIST:
      return FilterWatchlist;
    case FILTER_TYPES.RADIO:
      return FilterRadios;
    case FILTER_TYPES.SLIDER:
      return FilterSlider;
    case FILTER_TYPES.TEXTBOX:
      return FilterTextBox;
    default:
      return null;
  }
};
export var getStringValue = function getStringValue(_ref) {
  var upperCase = _ref.upperCase,
    value = _ref.value;
  var stringValue = value.map(function (ticker) {
    return ticker.trim();
  }).join(', ');
  if (upperCase) return stringValue.toUpperCase();
  return stringValue;
};
export var parseFilterParamsFromQS = function parseFilterParamsFromQS(searchParams) {
  var result = null;
  if (searchParams) {
    var parsedObj = null;
    if (searchParams.includes('%2C%20')) {
      searchParams = searchParams.replace(/%2C%20/g, '@@');
      parsedObj = qs.parse(searchParams, {
        arrayFormat: 'comma'
      });
      parsedObj = Object.keys(parsedObj).map(function (key) {
        var _ref2, _ref3;
        return Array.isArray(parsedObj[key]) ? (_ref2 = {}, _ref2[key] = parsedObj[key].map(function (item) {
          return item.replace(/@@/g, ', ');
        }), _ref2) : (_ref3 = {}, _ref3[key] = parsedObj[key].replace(/@@/g, ', '), _ref3);
      }).reduce(function (a, b) {
        return Object.assign(a, b);
      }, {});
    } else {
      parsedObj = qs.parse(searchParams, {
        arrayFormat: 'comma'
      });
    }
    var normalizedParsedObj = removeStatePropertyWithoutUnderscore(parsedObj);
    if (normalizedParsedObj) result = parsedObj;
  }
  return result;
};
export var parseFilterParamsFromQS2023 = function parseFilterParamsFromQS2023(searchParams, initialFilters) {
  if (searchParams) {
    var parsedObj = qs.parse(searchParams, {
      arrayFormat: 'comma',
      comma: true
    });
    var formattedParsedObjs = Object.entries(parsedObj).map(function (singleObj) {
      var _filterGroup$data;
      var key = singleObj[0],
        value = singleObj[1];

      // There was a problem when a user applied a watchlist on the old screener
      // and switched to the new Screener - the URL contained wrong watchlist filter format #12259
      if (key.includes('My Watchlists^')) key = 'watchlist';
      var _key$split = key.split('^'),
        filterGroupName = _key$split[0],
        filterId = _key$split[1];
      var filterGroup = initialFilters.find(function (initFilter) {
        return (initFilter == null ? void 0 : initFilter.name) === filterGroupName;
      });
      var filterGroupData = filterGroup == null || (_filterGroup$data = filterGroup.data) == null ? void 0 : _filterGroup$data.find(function (filterGroupData) {
        return (filterGroupData == null ? void 0 : filterGroupData.id) === filterId;
      });
      if (key === BENCHMARK_FILTER_KEY && value && typeof value === 'string') {
        return [key, [value.replaceAll(';', ',')]];
      }
      if (key === GENERAL_IS_PRIMARY_FILTER_KEY && typeof value === 'string' && value === 'all') {
        return [key, false];
      }
      if (['ticker_search', 'ticker_country'].includes(key) && Array.isArray(value)) {
        return [key, getStringValue({
          upperCase: true,
          value: value
        })];
      }
      if (['check', 'date_range'].some(function (v) {
        return v === (filterGroupData == null ? void 0 : filterGroupData.type);
      })) {
        var dataAsArray = [].concat(value);
        return [key, dataAsArray];
      }
      if (['radio'].some(function (v) {
        return v === (filterGroupData == null ? void 0 : filterGroupData.type);
      })) {
        value = stringToBoolean(value);
      }
      return [key, value];
    });
    return Object.fromEntries(formattedParsedObjs);
  }
  return {
    view: 'overview'
  };
};
export var prepeareFiltersToSerialize = function prepeareFiltersToSerialize(filters) {
  var clonedFilters = _cloneDeep(filters);
  Object.keys(clonedFilters).forEach(function (key) {
    if (key === BENCHMARK_FILTER_KEY && Array.isArray(clonedFilters[key]) && clonedFilters[key].length === 1) {
      clonedFilters[key][0] = clonedFilters[key][0].replaceAll(',', ';');
    }
  });
  return clonedFilters;
};
export var removeParentNameFromKeys = function removeParentNameFromKeys(state) {
  var normalizedState = removeStatePropertyWithoutUnderscore(state);
  if (!normalizedState) return null;
  var newObject = Object.keys(normalizedState).reduce(function (prevObj, key) {
    var _Object$assign;
    var splitKey = key.split('^');
    if (splitKey[0] === 'ticker_country') {
      return Object.assign(prevObj, {
        ticker_country: normalizedState[key]
      });
    }
    if (splitKey[0] === 'ticker_search') {
      return Object.assign(prevObj, {
        ticker_search: normalizedState[key]
      });
    }
    if (splitKey[0] === 'portfolio_search') {
      return Object.assign(prevObj, {
        portfolio_search: normalizedState[key]
      });
    }
    if (splitKey[1].includes(FILTER_URL_NAME_SEPARATOR)) {
      splitKey[1] = splitKey[1].substr(0, splitKey[1].indexOf(FILTER_URL_NAME_SEPARATOR));
    }
    return Object.assign(prevObj, (_Object$assign = {}, _Object$assign[splitKey[1]] = normalizedState[key], _Object$assign));
  }, {});
  return newObject;
};
export var removeStatePropertyWithoutUnderscore = function removeStatePropertyWithoutUnderscore(state) {
  if (!state) return null;
  var newObject = Object.keys(state).reduce(function (prevObj, key) {
    var _Object$assign2;
    var splitKey = key.split('^');
    if (splitKey.length === 1) {
      if (splitKey[0] === 'ticker_country') {
        return Object.assign(prevObj, {
          ticker_country: state[key]
        });
      }
      if (splitKey[0] === 'ticker_search') {
        return Object.assign(prevObj, {
          ticker_search: state[key]
        });
      }
      if (splitKey[0] === 'portfolio_search') {
        return Object.assign(prevObj, {
          portfolio_search: state[key]
        });
      }
      return prevObj;
    }
    return Object.assign(prevObj, (_Object$assign2 = {}, _Object$assign2[key] = state[key], _Object$assign2));
  }, {});
  return Object.keys(newObject).length === 0 ? null : newObject;
};
export var serializeStateToQS = function serializeStateToQS(object) {
  return object ? qs.stringify(object, {
    arrayFormat: 'comma'
  }) : '';
};

//parseFilterParamsFromQS2023 converts boolean to a string
//this helper changes it back to a bool type
var stringToBoolean = function stringToBoolean(value) {
  //Note: eq-screener uses "primary listings" as "True"
  //(it should stay as a string - if you want to make it bool type,
  //the BE should make a change first)

  //Note: we use this helper in the investor-flow-data machine for now,
  //it changes "liststatus": "true" back to a bool type

  //Note: some radio buttons have a value as an array
  //like in the /investor-flow-data machine
  //Symbol-Month value in Group By (Analytics) is ['symbol', 'month']
  if (typeof value !== 'string') return value;
  switch (value == null ? void 0 : value.trim()) {
    case 'true':
      //in the future we could add here cases to handle 'True', 'yes', '1'
      return true;
    case 'false':
      //in the future we could add here cases
      //to handle 'False', 'no', '0', null, undefined
      return false;
    default:
      return value;
  }
};
function _extends() { _extends = Object.assign ? Object.assign.bind() : function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; return _extends.apply(this, arguments); }
import { cloneDeep } from 'lodash';
import { isFloat } from '../../utils';
import { v4 as uuidv4 } from 'uuid';
import { setToast } from '../../redux/actionCreators';
export var calcTotalValue = function calcTotalValue(records) {
  return Number(records.reduce(function (acc, item) {
    return acc + item.size;
  }, 0).toFixed(3));
};
export var displayBenchmarkToast = function displayBenchmarkToast(_ref) {
  var benchKey = _ref.benchKey,
    dispatch = _ref.dispatch;
  dispatch(setToast({
    id: uuidv4(),
    message: "Benchmark " + (benchKey === 'no_benchmark' ? 'has been removed' : 'is set') + " successfully",
    type: 'success'
  }));
};
export var getNewName = function getNewName(context) {
  return "New " + (context === 'watchlists' ? 'Watchlist' : 'Portfolio') + " " + window.moment().format('MM.DD.YYYY HH:mm:ss');
};
export var getUpdatePortfolioMessage = function getUpdatePortfolioMessage(_ref2) {
  var id = _ref2.id,
    isCopy = _ref2.isCopy,
    name = _ref2.name;
  if (!isCopy || !id) return name + " successfully saved.";
  var path = "" + (id.includes('watchlist-') ? 'watchlists' : 'portfolios');
  path = path + "?" + path.substr(0, path.length - 1) + "=" + id;
  var context = id.includes('watchlist-') ? 'watchlist' : 'portfolio';
  return "The new copy - " + name + " has been created. You can <a key=\"redirect\" rel='noopener noreferrer' href='/" + path + "'>edit</a> this " + context + ".";
};
export var PW_ACTION_TYPE = {
  ANALYZE: 'ANALYZE',
  COACH: 'COACH',
  COMPARE_SECURITIES: 'COMPARE_SECURITIES',
  COMPARE_SIDE_BY_SIDE: 'COMPARE_SIDE_BY_SIDE',
  CONVERT_TO_WATCHLIST: 'CONVERT_TO_WATCHLIST',
  CONVERT_TO_PORTFOLIO: 'CONVERT_TO_PORTFOLIO',
  DOWNLOAD: 'DOWNLOAD',
  NEW: 'NEW',
  SAVE: 'SAVE',
  SAVE_AS_COPY: 'SAVE_AS_COPY',
  SET_BENCHMARK: 'SET_BENCHMARK',
  UPLOAD: 'UPLOAD'
};
export var PW_ACTIONS = {
  analysis: [{
    label: 'Save',
    value: PW_ACTION_TYPE.SAVE
  }, {
    label: 'Save as Copy',
    value: PW_ACTION_TYPE.SAVE_AS_COPY
  }, {
    label: 'Compare Side-by-Side',
    value: PW_ACTION_TYPE.COMPARE_SIDE_BY_SIDE
  }, {
    label: 'Compare Securities',
    value: PW_ACTION_TYPE.COMPARE_SECURITIES
  }, {
    label: 'Download',
    value: PW_ACTION_TYPE.DOWNLOAD
  }, {
    label: 'New',
    value: PW_ACTION_TYPE.NEW
  }, {
    label: 'Upload',
    value: PW_ACTION_TYPE.UPLOAD
  }, {
    label: 'Set Benchmark',
    value: PW_ACTION_TYPE.SET_BENCHMARK
  }],
  compare: [{
    label: 'Save',
    value: PW_ACTION_TYPE.SAVE
  }, {
    label: 'Save as Copy',
    value: PW_ACTION_TYPE.SAVE_AS_COPY
  }, {
    label: 'Analyze',
    value: PW_ACTION_TYPE.ANALYZE
  }, {
    label: 'Compare Securities',
    value: PW_ACTION_TYPE.COMPARE_SECURITIES
  }, {
    label: 'Download',
    value: PW_ACTION_TYPE.DOWNLOAD
  }, {
    label: 'New',
    value: PW_ACTION_TYPE.NEW
  }, {
    label: 'Upload',
    value: PW_ACTION_TYPE.UPLOAD
  }, {
    label: 'Set Benchmark',
    value: PW_ACTION_TYPE.SET_BENCHMARK
  }],
  init: [{
    label: 'Save',
    value: PW_ACTION_TYPE.SAVE
  }, {
    label: 'Save as Copy',
    value: PW_ACTION_TYPE.SAVE_AS_COPY
  }, {
    label: 'Analyze',
    value: PW_ACTION_TYPE.ANALYZE
  }, {
    label: 'Compare Side-by-Side',
    value: PW_ACTION_TYPE.COMPARE_SIDE_BY_SIDE
  }, {
    label: 'Compare Securities',
    value: PW_ACTION_TYPE.COMPARE_SECURITIES
  }, {
    label: 'Coach',
    value: PW_ACTION_TYPE.COACH
  }, {
    label: 'Download',
    value: PW_ACTION_TYPE.DOWNLOAD
  }, {
    label: 'New',
    value: PW_ACTION_TYPE.NEW
  }, {
    label: 'Upload',
    value: PW_ACTION_TYPE.UPLOAD
  }, {
    label: 'Set Benchmark',
    value: PW_ACTION_TYPE.SET_BENCHMARK
  }],
  'portfolio-modal': [{
    label: 'Save',
    value: PW_ACTION_TYPE.SAVE
  }, {
    label: 'Analyze',
    value: PW_ACTION_TYPE.ANALYZE
  }, {
    label: 'Compare Side-by-Side',
    value: PW_ACTION_TYPE.COMPARE_SIDE_BY_SIDE
  }, {
    label: 'Compare Securities',
    value: PW_ACTION_TYPE.COMPARE_SECURITIES
  }, {
    label: 'Download',
    value: PW_ACTION_TYPE.DOWNLOAD
  }],
  portfoliosScreener: [{
    label: 'Save',
    value: PW_ACTION_TYPE.SAVE
  }, {
    label: 'Save as Copy',
    value: PW_ACTION_TYPE.SAVE_AS_COPY
  }, {
    label: 'Analyze',
    value: PW_ACTION_TYPE.ANALYZE
  }, {
    label: 'Compare Side-by-Side',
    value: PW_ACTION_TYPE.COMPARE_SIDE_BY_SIDE
  }, {
    label: 'Compare Securities',
    value: PW_ACTION_TYPE.COMPARE_SECURITIES
  }, {
    label: 'Coach',
    value: PW_ACTION_TYPE.COACH
  }, {
    label: 'Download',
    value: PW_ACTION_TYPE.DOWNLOAD
  }, {
    label: 'New',
    value: PW_ACTION_TYPE.NEW
  }, {
    label: 'Upload',
    value: PW_ACTION_TYPE.UPLOAD
  }, {
    label: 'Set Benchmark',
    value: PW_ACTION_TYPE.SET_BENCHMARK
  }, {
    label: 'Convert to watchlist',
    value: PW_ACTION_TYPE.CONVERT_TO_WATCHLIST
  }],
  watchlists: [{
    label: 'Save',
    value: PW_ACTION_TYPE.SAVE
  }, {
    label: 'Save as Copy',
    value: PW_ACTION_TYPE.SAVE_AS_COPY
  }, {
    label: 'Download',
    value: PW_ACTION_TYPE.DOWNLOAD
  }, {
    label: 'New',
    value: PW_ACTION_TYPE.NEW
  }, {
    label: 'Upload',
    value: PW_ACTION_TYPE.UPLOAD
  }, {
    label: 'Set Benchmark',
    value: PW_ACTION_TYPE.SET_BENCHMARK
  }, {
    label: 'Convert to portfolio',
    value: PW_ACTION_TYPE.CONVERT_TO_PORTFOLIO
  }]
};
export var portfolioConverter = function portfolioConverter(_ref3) {
  var convertTo = _ref3.convertTo,
    name = _ref3.name,
    data = _ref3.data;
  if (convertTo === 'watchlist') {
    var records = [].concat(data.records).map(function (row) {
      return _extends({}, row, {
        size: 100
      });
    });
    return _extends({}, data, {
      id: "watchlist-" + uuidv4(),
      name: name || getNewName('watchlists'),
      records: records,
      size_type: 'shares'
    });
  }
  return _extends({}, data, {
    id: uuidv4(),
    name: name || getNewName('portfolios')
  });
};
export var setEqualSize = function setEqualSize(records) {
  var copyRows = cloneDeep(records);
  var rowsLenth = records.length;
  copyRows.forEach(function (row) {
    row.size = Number((100 / rowsLenth).toFixed(2));
  });
  var values = copyRows.map(function (x) {
    return x.size;
  }).reduce(function (acc, cur) {
    return acc + cur;
  }, 0);
  var missing_weight = 100 - values;
  copyRows[rowsLenth - 1].size += missing_weight;
  if (isFloat(copyRows[rowsLenth - 1].size)) {
    copyRows[rowsLenth - 1].size = Number(copyRows[rowsLenth - 1].size.toFixed(2));
  }
  return copyRows;
};
export var TOOLTIP_READ_ONLY_MESSAGE = "This portfolio is in read-only mode and can't be edited";
export var TOOLTIP_READ_ONLY_UPLOAD_MESSAGE = "This portfolio has been uploaded and can't be edited. Please save the portfolio.";
export var PREDEFINED_BENCHMARKS = [{
  name: 'Benchmark Automatically Selected',
  key: 'auto_benchmark'
}, {
  name: 'No Benchmark',
  key: 'no_benchmark'
}, {
  name: '60% Equities / 40% Fixed Income',
  key: 'etflogic_60eq_40fi_benchmark'
}, {
  name: '90% Equities / 10% Fixed Income',
  key: 'etflogic_90eq_10fi_benchmark'
}, {
  name: '10% Equities / 90% Fixed Income',
  key: 'etflogic_10eq_90fi_benchmark'
}];
import CheckboxFilter from './CheckboxFilter';
import CustomFilter from './CustomFilter';
import DateRangeFilter from './DateRangeFilter';
import RadioFilter from './RadioFilter';
import SliderFilter from './SliderFilter';
import TextboxFilter from './TextboxFilter';
import WatchlistFilter from './WatchlistFilter';
export var FILTER_TYPES = {
  CHECK: 'check',
  CUSTOM_FILTERS: 'custom_filters',
  DATE_RANGE: 'date_range',
  LINKS: 'links',
  LINKS_WATCHLIST: 'links-watchlist',
  RADIO: 'radio',
  SLIDER: 'slider',
  TEXTBOX: 'textbox'
};
var getCompByFilterType = function getCompByFilterType(type) {
  switch (type) {
    case FILTER_TYPES.CHECK:
      return CheckboxFilter;
    case FILTER_TYPES.DATE_RANGE:
      return DateRangeFilter;
    case FILTER_TYPES.CUSTOM_FILTERS:
      return CustomFilter;
    case FILTER_TYPES.LINKS_WATCHLIST:
      return WatchlistFilter;
    case FILTER_TYPES.RADIO:
      return RadioFilter;
    case FILTER_TYPES.SLIDER:
      return SliderFilter;
    case FILTER_TYPES.TEXTBOX:
      return TextboxFilter;
    default:
      return null;
  }
};
var useFilter = function useFilter(_ref) {
  var type = _ref.type;
  return getCompByFilterType(type);
};
export default useFilter;
/* eslint-disable import/prefer-default-export */
import { useEffect, useRef } from 'react';
export var useTimeout = function useTimeout() {
  var timer = useRef(null);
  useEffect(function () {
    return function () {
      timer.current && clearTimeout(timer.current);
    };
  }, []);
  var callFunctionWithTimeout = function callFunctionWithTimeout(_ref) {
    var callback = _ref.callback,
      delay = _ref.delay,
      disableCallback = _ref.disableCallback;
    if (timer.current) clearTimeout(timer.current);
    if (disableCallback) return;
    timer.current = setTimeout(function () {
      callback();
    }, delay);
  };
  return {
    callFunctionWithTimeout: callFunctionWithTimeout
  };
};
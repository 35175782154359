/* eslint-disable import/prefer-default-export */
var TMX_USERS = ['TMXAdministratorProd', 'TMXUserProd', 'TMXAdministratorTest', 'TMXUserTest'];
export var checkIsTMXMode = function checkIsTMXMode(user) {
  if (!user || !(user != null && user.signInUserSession)) return;
  var groups = user.signInUserSession.idToken.payload['cognito:groups'];
  var isTMX = groups == null ? void 0 : groups.some(function (group) {
    return TMX_USERS.includes(group);
  });
  window.tmxMode = isTMX;
  return isTMX;
};